import React from "react";

const Tabungan = () => {
  return (
    <>
      <div className="w-11/12 lg:w-full py-6 lg:pt-10 mx-auto flex flex-col items-center justify-center gap-5">
        <div className="text-gray-500 font-bold text-center  text-fmd lg:text-flg">
          Segera nikmati banyak fitur dengan mendownload aplikasi Bisatopup di
          Playstore
        </div>
        <a
          href="https://berbagi.link/bisatopup/download"
          target="_blank"
          class="bg-primary text-center shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-4/12 md:w-3/12 xl:w-2/12 rounded-md xl:rounded-2xl py-1 lg:py-3"
        >
          <button class="text-white text-fsm lg:text-fmd xl:text-fbase font-bold">
            Download{" "}
          </button>
        </a>
      </div>
    </>
  );
};

export default Tabungan;
