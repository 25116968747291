import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faTableList } from "@fortawesome/free-solid-svg-icons";

import pembelianImg from "../../images/fitur_pembelian.png";
import pembayaranImg from "../../images/fitur_pembayaran.png";
import pemesananImg from "../../images/fitur_pemesanan.png";
import lainnyaImg from "../../images/fitur_lainnya.png";

const DownloadPlaystore = () => {
    const swipeContents = [
        {
            id: "1",
            title: "Pembelian",
            icon: "1",
            desc: "Menyediakan berbagai kebutuhan harian kamu agar transaksi lebih praktis.",
            image: pembelianImg,
            icon: faShoppingBag,
        },
        {
            id: "2",
            title: "Pemesanan",
            icon: "2",
            desc: "Menyediakan pemesanan tiket transportasi dan lainnya.",
            image: pemesananImg,
            icon: faTicket,
        },
        {
            id: "3",
            title: "Pembayaran",
            icon: "3",
            desc: "Memudahkan proses pembayaran kebutuhan penting baik harian maupun bulanan anda.",
            image: pembayaranImg,
            icon: faCreditCard,
        },
        {
            id: "4",
            title: "lainnya",
            icon: "4",
            desc: "Memudahkan anda untuk zakat, infaq, wakaf, shodakoh dan penunjang menarik lainnya.",
            image: lainnyaImg,
            icon: faTableList,
        },
    ];
    const pathname = window.location.pathname;
    const lastPart = pathname.split("/").pop();
    const href = `https://play.google.com/store/apps/details?id=com.amanahcorp.bisatopup&utm_source=bisatopup.co.id&utm_medium=prtnr&utm_content=${lastPart}&utm_campaign=web&pcampaignid=${lastPart}`;
    return (
        <>
        <Navbar active="daftarProduk" />
        <section className="p-0">
            <section className="md:w-11/12 lg:w-10/12 mx-auto">
            <div className="md:grid grid-cols-12">
                <div className="md:hidden">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                        delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {swipeContents.map((content, contentIdx) => {
                        return (
                            <SwiperSlide key={contentIdx}>
                                <div className="w-10/12 mx-auto grid grid-cols-12">
                                    <div className="col-span-12 md:col-span-12 ml-auto">
                                    <img
                                        className="w-4/5 ml-auto"
                                        src={content.image}
                                        alt=""
                                    />
                                    </div>
                                </div>
                            </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            </section>

            {/*  <!-- Web View --> */}
            <div className="hidden md:grid items-center mt-5 grid-cols-12 gap-4">
            <div className="col-span-1"></div>
            <div className="col-span-4 flex flex-col gap-3 lg:gap-5 my-10">
                <h1 className="text-flg lg:text-fxl xl:text-f2xl font-bold leading-tight">
                    Satu Aplikasi Banyak <br />
                    Manfaat, Banyak <br />
                    Untungnya
                </h1>

                <p className="text-fsm lg:text-fmd xl:text-fbase font-light text-gray-400">
                    Aplikasi yang memudahkan kamu untuk pembayaran <br />
                    dimanapun dan kapanpun!
                </p>

                <div className="flex gap-3 lg:gap-4 w-10/12 xl:w-9/12">
                    <div className="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
                        <div className="mr-3">
                            <svg viewBox="30 336.7 120.9 129.2" width="30">
                                <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                                <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                                <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                                <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                            </svg>
                        </div>
                        <a rel="external" href={href}>
                            <div className="text-xs">GET IT ON</div>
                            <div className="text-xl font-semibold font-sans -mt-1">Google Play</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-span-7 p-0">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                    delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {swipeContents.map((content, contentIdx) => {
                    return (
                        <SwiperSlide key={contentIdx}>
                            <div className="w-10/12 mx-auto grid grid-cols-12">
                                <div className="col-span-12 md:col-span-12 ml-auto">
                                <img
                                    className="w-4/5 ml-auto"
                                    src={content.image}
                                    alt=""
                                />
                                </div>
                            </div>
                        </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            </div>

            {/* <!-- Mobile view --> */}
            <div className="md:hidden w-10/12 mx-auto">
            <div className="w-11/12 flex flex-col gap-2 py-4">
                <h1 className="text-flg font-bold leading-tight">
                Satu Aplikasi Banyak <br />
                Manfaat, Banyak <br />
                Untungnya
                </h1>

                <p className="text-fsm text-gray-400">
                Aplikasi yang memudahkan kamu untuk <br />
                pembayaran dimanapun dan kapanpun!
                </p>

                <div className="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
                    <div className="mr-3">
                        <svg viewBox="30 336.7 120.9 129.2" width="30">
                            <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                            <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                            <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                            <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                        </svg>
                    </div>
                    <a rel="external" href={href}>
                        <div className="text-xs">GET IT ON</div>
                        <div className="text-xl font-semibold font-sans -mt-1">Google Play</div>
                    </a>
                </div>
            </div>
            </div>
      </section>
    </>
  );
};

export default DownloadPlaystore;
