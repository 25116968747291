import React, { useState } from "react";
import Select from "react-select";

import kereta1 from "../../../../images/kereta1.png";
import pesawat1 from "../../../../images/pesawat1.png";
import bus1 from "../../../../images/bus1.png";
import kemitraan1 from "../../../../images/kemitraan1.png";
import umroh1 from "../../../../images/umroh1.png";
import hotel from "../../../../images/hotel.png";
import indosat from "../../../../images/indosat.png";

const PemesananTab = () => {
  const [currentLayananTab, setCurrentLayananTab] = useState(1);

  const tabs = [
    {
      id: 1,
      tabTitle: "Tiket Kereta",
      icon: kereta1,
    },
    {
      id: 2,
      tabTitle: "Tiket Pesawat",
      icon: pesawat1,
    },
    {
      id: 3,
      tabTitle: "Tiket Bus",
      icon: bus1,
    },
    {
      id: 4,
      tabTitle: "Produk Kemitraan",
      icon: kemitraan1,
    },
    {
      id: 5,
      tabTitle: "Tiket Hotel",
      icon: hotel,
    },
    {
      id: 6,
      tabTitle: "Tiket Haji & Umroh",
      icon: umroh1,
    },
  ];

  const handleTabClick = (id) => {
    setCurrentLayananTab(id);
  };
  return (
    <>
      <div className="grid grid-cols-6 px-2 y-4 gap-2 md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
        {tabs.map((tab, tabIdx) => {
          return (
            <button
              disabled={currentLayananTab === tab.id}
              key={tabIdx}
              id={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`${
                currentLayananTab === tab.id ? "border-b-4" : ""
              }  text-center mx-auto hover:border-b-4 border-orange-400 py-3 xl:py-4`}
            >
              <img
                src={tab.icon}
                className="h-5 md:h-6 lg:h-7 mb-2 md:mb-3 lg:mb-4 mx-auto"
                alt=""
              />
              <p className="text-fxs md:text-fsm lg:text-fmd font-bold text-primary">
                {tab.tabTitle}
              </p>
            </button>
          );
        })}
      </div>
      <div className="w-11/12 lg:w-full py-6 lg:pt-10 mx-auto flex flex-col items-center justify-center gap-5">
        <div className="text-gray-500 font-bold text-center  text-fmd lg:text-flg">
          Segera nikmati banyak fitur dengan mendownload aplikasi Bisatopup di
          Playstore
        </div>
        <a
          href="https://berbagi.link/bisatopup/download"
          target="_blank"
          class="bg-primary text-center shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-4/12 md:w-3/12 xl:w-2/12 rounded-md xl:rounded-2xl py-1 lg:py-3"
        >
          <button class="text-white text-fsm lg:text-fmd xl:text-fbase font-bold">
            Download{" "}
          </button>
        </a>
      </div>
      {/* {tabs.map((tab, tabIdx) => {
        return (
          <>
            <div key={tabIdx} id={tab.tabTitle}>
              {currentLayananTab === tab.id ? tab.content : ""}
            </div>
          </>
        );
      })} */}
    </>
  );
};

export default PemesananTab;
