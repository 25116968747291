import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import apiConfig from "../../../../../api/apiConfig";
import Select from "react-select";

const Ziswaf = () => {
  const [jenisDonasi, setJenisDonasi] = useState({
    jenis: [],
    tujuan: [],
  });

  const [selectedOption, setSelectedOption] = useState({
    label: "Select...",
    value: null,
  });

  const [nominal, setNominal] = useState([
    {
      value: 1,
      label: 10000,
    },
    {
      value: 2,
      label: 20000,
    },
    {
      value: 3,
      label: 50000,
    },
    {
      value: 4,
      label: 100000,
    },
    {
      value: 5,
      label: 150000,
    },
    {
      value: 6,
      label: 500000,
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setSelectedOption({ ...selectedOption, label: e.label, value: e.value });
  };

  const getJenis = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl + `bisabiller/product/ziswaf-category`}`
      );
      const res = response.data.data;
      // console.log(res);
      setJenisDonasi({
        ...jenisDonasi,
        jenis: res.map((item) => {
          return {
            value: item.type,
            id: item.id,
            label: (
              <div className="flex">
                <div className="text-fmd my-auto">
                  <h5>{item.type}</h5>
                </div>
              </div>
            ),
          };
        }),
      });

      // Success 🎉
    } catch (error) {
      Swal.fire({
        title: "Failed",
        text: "Terjadi error",
        icon: "error",
        confirmButtonText: "OK",
      });
      // Error 😨

      // console.log(error);
    }
  };

  const getTujuan = async (e) => {
    const id = e.id;
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl + `bisabiller/product/ziswaf-campaign/${id}`}`
      );
      const res = response.data.data;
      console.log(res);
      setJenisDonasi({
        ...jenisDonasi,
        tujuan: res.map((item) => {
          return {
            value: item.title,
            id: item.id,
            label: (
              <div className="flex">
                <div className="text-fmd my-auto">
                  <h5>{item.title}</h5>
                </div>
              </div>
            ),
          };
        }),
      });

      // Success 🎉
    } catch (error) {
      Swal.fire({
        title: "Failed",
        text: "Terjadi error",
        icon: "error",
        confirmButtonText: "OK",
      });
      // Error 😨

      // console.log(error);
    }
  };

  useEffect(() => {
    getJenis();
  }, []);

  return (
    <>
      <div className="w-11/12 lg:w-full lg:pt-10 mx-auto lg:flex items-center justify-center gap-12">
        <div className="grid grid-cols-1 lg:grid-cols-12 lg:w-11/12 mx-auto lg:gap-3 xl:gap-5 ">
          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              Jenis Donasi{" "}
            </h5>

            <div className="select_wrap ">
              <Select
                options={jenisDonasi.jenis}
                onChange={(e) => (
                  getTujuan(e),
                  setSelectedOption({
                    ...selectedOption,
                    label: "Select...",
                    value: null,
                  })
                )}
                className="max-h-32 "
              />
            </div>
          </div>

          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              Tujuan Donasi{" "}
            </h5>

            <div className="select_wrap ">
              <Select
                options={jenisDonasi.tujuan}
                value={selectedOption}
                onChange={(e) => handleChange(e)}
                className="max-h-32"
                // onChange={(e) =>
                //   setData({
                //     ...data,
                //     type: `${e.value}`,
                //   })
                // }
              />
            </div>
          </div>

          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              Nominal Donasi
            </h5>

            <div className="select_wrap ">
              <Select
                options={nominal}
                // onChange={(e) =>
                //   setData({
                //     ...data,
                //     label: `${IDR(e.price).format()}`,
                //   })
                // }
                className="max-h-32"
              />
            </div>
          </div>

          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              Catatan{" "}
            </h5>

            <div className="select_wrap flex flex-col md:flex-row gap-x-2 gap-y-2">
              <input
                className="border-2 rounded-md w-full h-9 md:h-10 px-3"
                // value={data?.label}
                type="text"
              />
              <a
                href="https://berbagi.link/bisatopup/download"
                target={"_blank"}
                className="bg-primary text-white text-center px-4 rounded-md py-1 md:py-2 md:rounded-lg md:flex md:my-auto"
              >
                Donasi
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ziswaf;
