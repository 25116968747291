import React from "react";

const Feedback = () => {
  return (
    <>
      <section className="my-8 lg:my-14 xl:my-16">
        {/* <!-- Testimoni --> */}
        <section
          className="w-11/12 mx-auto py-7 md:py-10"
          style="background-color: #fffbf4; height: auto"
        >
          <div className="text-center">
            <div className="mb-8 md:mb-10 lg:mb-14">
              <h6 className="font-bold text-fsm md:text-fmd text-orange-400 mb-2">
                ULASAN DARI PENGGUNA
              </h6>
              <h1 className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Apa Kata Mereka?
              </h1>
            </div>

            <div
              className="flex justify-center items-center"
              style="min-height: auto"
            >
              <div className="testimonials">
                <div className="swiper mySwiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="card">
                        <div className="bg-white w-9/12 md:w-11/12 mx-auto rounded-lg lg:rounded-xl border">
                          <div className="text-left w-10/12 mx-auto py-6 lg:py-8">
                            <div className="flex items-center gap-3">
                              <div>
                                <img
                                  src="/src/img/avatar.png"
                                  className="h-12 md:h-16 xl:h-20"
                                  alt=""
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-fsm md:text-fmd xl:text-fbase font-bold">
                                  Abdurrahman1
                                </p>
                                <p className="text-fxs md:text-fsm xl:text-fmd text-gray-400">
                                  Google Play User
                                </p>
                                <div className="flex text-fxs md:text-fsm text-orange-300">
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                </div>
                              </div>
                            </div>

                            <p className="text-fxs md:text-fsm xl:text-fmd leading-snug text-gray-500 mt-5">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="card">
                        <div className="bg-white w-9/12 md:w-11/12 mx-auto rounded-lg lg:rounded-xl border">
                          <div className="text-left w-10/12 mx-auto py-6 lg:py-8">
                            <div className="flex items-center gap-3">
                              <div>
                                <img
                                  src="/src/img/avatar.png"
                                  className="h-12 md:h-16 xl:h-20"
                                  alt=""
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-fsm md:text-fmd xl:text-fbase font-bold">
                                  Abdurrahman1
                                </p>
                                <p className="text-fxs md:text-fsm xl:text-fmd text-gray-400">
                                  Google Play User
                                </p>
                                <div className="flex text-fxs md:text-fsm text-orange-300">
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                </div>
                              </div>
                            </div>

                            <p className="text-fxs md:text-fsm xl:text-fmd leading-snug text-gray-500 mt-5">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="card">
                        <div className="bg-white w-9/12 md:w-11/12 mx-auto rounded-lg lg:rounded-xl border">
                          <div className="text-left w-10/12 mx-auto py-6 lg:py-8">
                            <div className="flex items-center gap-3">
                              <div>
                                <img
                                  src="/src/img/avatar.png"
                                  className="h-12 md:h-16 xl:h-20"
                                  alt=""
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-fsm md:text-fmd xl:text-fbase font-bold">
                                  Abdurrahman1
                                </p>
                                <p className="text-fxs md:text-fsm xl:text-fmd text-gray-400">
                                  Google Play User
                                </p>
                                <div className="flex text-fxs md:text-fsm text-orange-300">
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                </div>
                              </div>
                            </div>

                            <p className="text-fxs md:text-fsm xl:text-fmd leading-snug text-gray-500 mt-5">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="card">
                        <div className="bg-white w-9/12 md:w-11/12 mx-auto rounded-lg lg:rounded-xl border">
                          <div className="text-left w-10/12 mx-auto py-6 lg:py-8">
                            <div className="flex items-center gap-3">
                              <div>
                                <img
                                  src="/src/img/avatar.png"
                                  className="h-12 md:h-16 xl:h-20"
                                  alt=""
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-fsm md:text-fmd xl:text-fbase font-bold">
                                  Abdurrahman1
                                </p>
                                <p className="text-fxs md:text-fsm xl:text-fmd text-gray-400">
                                  Google Play User
                                </p>
                                <div className="flex text-fxs md:text-fsm text-orange-300">
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                </div>
                              </div>
                            </div>

                            <p className="text-fxs md:text-fsm xl:text-fmd leading-snug text-gray-500 mt-5">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="absolute top-16 right-2 hover:text-orange-500 hover:border-orange-500 md:hidden">
                    <i className="fas fa-chevron-right py-2 xl:py-2 xl:text-fbase px-2 xl:px-3 border-2 text-orange-400 border-orange-400 opacity-30 hover:opacity-100 rounded-full"></i>
                  </button>
                  <button className="absolute top-16 left-2 hover:text-orange-500 hover:border-orange-500 md:hidden">
                    <i className="fas fa-chevron-left py-2 xl:py-2 xl:text-fbase px-2 xl:px-3 border-2 text-orange-400 border-orange-400 opacity-30 hover:opacity-100 rounded-full"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Feedback;
