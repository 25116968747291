import React, { useState } from "react";
import "./style.scss";

import PembayaranTab from "./PembayaranTab/PembayaranTab";
import PembelianTab from "./PembelianTab/PembelianTab";
import PemesananTab from "./PemesananTab/PemesananTab";
import LainnyaTab from "./LainnyaTab/LainnyaTab";

const Layanan = () => {
  const [currentLayananTab, setCurrentLayananTab] = useState("1");

  const tabs = [
    {
      id: 1,
      tabTitle: "Pembelian",
      bgTitle: "bg-primary",
      content: <PembelianTab />,
    },
    {
      id: 2,
      tabTitle: "Pemesanan",
      bgTitle: "bg-biru-terang",
      content: <PemesananTab />,
    },
    {
      id: 3,
      tabTitle: "Pembayaran",
      bgTitle: "bg-orange-terang",
      content: <PembayaranTab />,
    },
    {
      id: 4,
      tabTitle: "Lainnya",
      bgTitle: "bg-pink-terang",
      content: <LainnyaTab />,
    },
  ];

  const handleTabClick = (e) => {
    setCurrentLayananTab(e.target.id);
  };

  return (
    <>
      <section className="my-8">
        <section className="h-auto">
          <div className="bg-putih-200 rounded-3xl inset-x-0 md:w-11/12 mx-auto md:block">
            <h1 className="text-center text-fmd md:text-fbase my-6 font-bold">
              Hai, sedang butuh apa?
            </h1>
            <div id="wrapper">
              <div
                id="buttonWrapper"
                className="w-11/12 mx-auto flex text-white items-end"
              >
                {tabs.map((tab, tabIdx) => {
                  return (
                    <button
                      disabled={currentLayananTab === `${tab.id}`}
                      onClick={handleTabClick}
                      id={tab.id}
                      key={tabIdx}
                      className={`${
                        currentLayananTab === `${tab.id}` ? "pt-6" : ""
                      }  ${
                        tab.bgTitle
                      } tab-button layanan-links w-20 md:w-32 lg:w-40 py-2 text-center font-bold hover:text-gray-100 rounded-t-[20px] text-fxs lg:text-fmd`}
                    >
                      {tab.tabTitle}
                    </button>
                  );
                })}
              </div>

              <div
                id="contentWrapper"
                className="border border-gray-300 mx-4 rounded-xl shadow-sm py-5"
              >
                {tabs.map((tab, tabIdx) => {
                  return (
                    <div key={tabIdx} id={tab.tabTitle} className="content">
                      {currentLayananTab === `${tab.id}` && tab.content}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Layanan;
