import React, { useEffect, useState } from "react";
import Select from "react-select";

import axios from "axios";
import apiConfig from "../../../../../api/apiConfig";
import Swal from "sweetalert2";
import currency from "currency.js";

const PulsaTab = ({ getProvider }) => {
  const [data, setData] = useState({});

  const [selectedOption, setSelectedOption] = useState({
    label: "Select...",
    value: null,
  });

  const [kartuPerdana, setKartuPerdana] = useState({
    nama: "Kartu Perdana",
    isOpen: false,
    items: [],
    detail: [],
  });

  const [jenisPulsa, setJenisPulsa] = useState({
    nama: "Jenis Pulsa",
    isOpen: false,
    items: [{ value: "Pulsa Reguler", label: "Pulsa Reguler" }],
  });

  const IDR = (value) =>
    currency(value, { symbol: "Rp. ", decimal: ",", separator: "." });

  const handleChange = (e) => {
    setSelectedOption({ ...selectedOption, label: e.label, value: e.value });
  };

  const getProviderDetail = async (e) => {
    try {
      let id = e.id;
      // console.log(id);
      const response = await axios.get(
        `${apiConfig.baseUrl + `bisabiller/product/v2/${id}`}`
      );
      const res = response.data.data;
      // console.log("detail", res);
      setKartuPerdana({
        ...kartuPerdana,
        detail: res.map((item, itemIdx) => {
          return {
            value: item.product_name,
            price: item.price,
            id: item.product_id,
            label: (
              <div key={itemIdx} className="flex">
                <div className="text-fmd my-auto">
                  <h5>{item.product_name}</h5>
                </div>
              </div>
            ),
          };
        }),
      });
      // setProviderSelected(res[0]);

      // Success 🎉
    } catch (error) {
      Swal.fire({
        title: "Failed",
        text: "Terjadi error",
        icon: "error",
        confirmButtonText: "OK",
      });
      // Error 😨

      // console.log(error);
    }
  };

  useEffect(() => {
    getProvider(1, kartuPerdana, setKartuPerdana);
  }, []);

  return (
    <>
      <div className="w-11/12 lg:w-full lg:pt-10 mx-auto lg:flex items-center justify-center gap-12">
        <div className="grid grid-cols-1 lg:grid-cols-12 lg:w-11/12 mx-auto lg:gap-3 xl:gap-5 ">
          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              {kartuPerdana.nama}
            </h5>

            <div className="select_wrap ">
              <Select
                options={kartuPerdana.items}
                onChange={(e) => (
                  getProviderDetail(e),
                  setData({
                    ...data,
                    title: `${e.id}`,
                    label: "",
                  }),
                  setSelectedOption({
                    ...selectedOption,
                    label: "Select...",
                    value: null,
                  })
                )}
                className="max-h-32 "
              />
            </div>
          </div>

          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              {jenisPulsa.nama}
            </h5>

            <div className="select_wrap ">
              <Select
                options={jenisPulsa.items}
                className="max-h-32"
                onChange={(e) =>
                  setData({
                    ...data,
                    type: `${e.value}`,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              Jumlah Topup
            </h5>

            <div className="select_wrap ">
              <Select
                options={kartuPerdana.detail}
                value={selectedOption}
                onChange={(e) => (
                  setData({
                    ...data,
                    label: `${IDR(e.price).format()}`,
                  }),
                  handleChange(e)
                )}
                className="max-h-32"
              />
            </div>
          </div>

          <div className="mt-3 md:mt-4 lg:my-0 col-span-1 lg:col-span-3">
            <h5 className="text-fsm md:text-base lg:text-fsm xl:text-fmd font-semibold lg:mb-4 lg:-mt-5">
              Total Harga
            </h5>

            <div className="select_wrap flex flex-col md:flex-row gap-x-2 gap-y-2">
              <input
                className="border-2 rounded-md w-full h-9 md:h-10 px-3"
                value={data?.label}
                type="text"
                disabled
              />
              <a
                href="https://berbagi.link/bisatopup/download"
                target={"_blank"}
                className="bg-primary text-white text-center px-4 rounded-md py-1 md:py-2 md:rounded-lg md:flex md:my-auto"
              >
                Beli
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PulsaTab;
