import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faTableList } from "@fortawesome/free-solid-svg-icons";

import pembelianImg from "../../images/fitur_pembelian.png";
import pembayaranImg from "../../images/fitur_pembayaran.png";
import pemesananImg from "../../images/fitur_pemesanan.png";
import lainnyaImg from "../../images/fitur_lainnya.png";

const Features = () => {
  const swipeContents = [
    {
      id: "1",
      title: "Pembelian",
      icon: "1",
      desc: "Menyediakan berbagai kebutuhan harian kamu agar transaksi lebih praktis.",
      image: pembelianImg,
      icon: faShoppingBag,
    },
    {
      id: "2",
      title: "Pemesanan",
      icon: "2",
      desc: "Menyediakan pemesanan tiket transportasi dan lainnya.",
      image: pemesananImg,
      icon: faTicket,
    },
    {
      id: "3",
      title: "Pembayaran",
      icon: "3",
      desc: "Memudahkan proses pembayaran kebutuhan penting baik harian maupun bulanan anda.",
      image: pembayaranImg,
      icon: faCreditCard,
    },
    {
      id: "4",
      title: "lainnya",
      icon: "4",
      desc: "Memudahkan anda untuk zakat, infaq, wakaf, shodakoh dan penunjang menarik lainnya.",
      image: lainnyaImg,
      icon: faTableList,
    },
  ];

  return (
    <>
      <section id="feature-section" className="my-8 lg:my-14 xl:my-16">
        <div
          className="w-11/12 mx-auto py-6 xl:py-10 rounded-xl lg:rounded-2xl"
          style={{ backgroundColor: "#fffbf4" }}
        >
          <div className="text-center">
            <h6 className="font-bold text-fsm md:text-fmd text-orange-300">
              FITUR CANGGIH DAN SUPER CEPAT
            </h6>
            <h1 className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight mt-2 md:mt-4 mb-4 md:mb-6 lg:mb-9 xl:mb-12">
              Fitur-fitur
            </h1>
          </div>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {swipeContents.map((content, contentIdx) => {
              return (
                <SwiperSlide key={contentIdx}>
                  <div className="w-10/12 mx-auto grid grid-cols-12">
                    <div className="col-span-4 md:col-span-6  pt-6 md:pt-10">
                      <FontAwesomeIcon
                        icon={content.icon}
                        className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl bg-white p-3 lg:p-4 xl:p-5 rounded-lg"
                      />
                      <h4 className="text-fsm md:text-fbase lg:text-flg xl:text-fxl font-bold pt-4 pb-1">
                        {content.title}
                      </h4>

                      <p className="text-fxs md:text-fmd lg:text-fbase xl:text-flg text-gray-400 w-3/4">
                        {content.desc}
                      </p>
                    </div>

                    <div className="col-span-8 md:col-span-6 ml-auto">
                      <img
                        className="w-3/4 ml-auto"
                        src={content.image}
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Features;
