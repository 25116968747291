import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import jumbotronImg from "../../images/content1.png";
import Layanan from "../LandingPage/Layanan/Layanan";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <Navbar active="services" />
      <header className="py-4 mb-10 md:py-0 ">
        <section className="w-11/12 lg:w-10/12 mx-auto">
          <div className="md:grid grid-cols-12">
            <div className="md:hidden">
              <img src={jumbotronImg} alt="" />
            </div>
          </div>
        </section>

        <div className="hidden md:grid items-center grid-cols-12  gap-4">
          <div className="col-span-1"></div>
          <div className="col-span-5 flex flex-col gap-3 lg:gap-5">
            <h1 className="text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
              Layanan <br />
              Bisatopup
            </h1>

            <p className="text-fsm lg:text-fmd xl:text-fbase font-light text-gray-400">
              Menyediakan berbagai produk untuk memudahkan <br /> kamu dalam
              pembelian, pembayaran, dan lainnya.
            </p>

            <div className="flex gap-3 lg:gap-4 w-10/12 xl:w-9/12">
              <Link
                to="/"
                className="bg-primary shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-2/3 rounded-xl xl:rounded-2xl py-3 xl:py-4 my-1"
              >
                <div className="flex items-center justify-center gap-2 md:gap-3 xl:gap-4 text-white text-fsm lg:text-fmd xl:text-fbase font-bold">
                  <h5>Lihat Layanan</h5>
                  <i className="fas fa-arrow-down"></i>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-span-6 p-0">
            <img src={jumbotronImg} className="w-full p-0" alt="" />
          </div>
        </div>
      </header>
      <Layanan />
      <Footer />
    </>
  );
};

export default Services;
