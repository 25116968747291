import React, { useState } from "react";
import perumahan from "../../../../images/perumahan.png";
import kuliah from "../../../../images/kuliah.png";
import penarikan from "../../../../images/penarikan.png";
import qurban from "../../../../images/qurban.png";
import ziswaf from "../../../../images/ziswaf.png";
import tabungan from "../../../../images/tabungan.png";
import Perumahan from "./partials/Perumahan";
import Kuliah from "./partials/Kuliah";
import PenarikanSaldo from "./partials/PenarikanSaldo";
import Qurban from "./partials/Qurban";
import Ziswaf from "./partials/Ziswaf";
import Tabungan from "./partials/Tabungan";

const LainnyaTab = () => {
  const [currentLayananTab, setCurrentLayananTab] = useState(4);

  const tabs = [
    {
      id: 1,
      tabTitle: "Iuran Perumahan",
      icon: perumahan,
      content: <Perumahan />,
    },
    {
      id: 2,
      tabTitle: "Pembayaran kuliah",
      icon: kuliah,
      content: <Kuliah />,
    },
    {
      id: 3,
      tabTitle: "Penarikan Saldo",
      icon: penarikan,
      content: <PenarikanSaldo />,
    },
    {
      id: 4,
      tabTitle: "Qurban & Aqiqah",
      icon: qurban,
      content: <Qurban />,
    },
    {
      id: 5,
      tabTitle: "Ziswaf",
      icon: ziswaf,
      content: <Ziswaf />,
    },
    {
      id: 6,
      tabTitle: "Tabungan",
      icon: tabungan,
      content: <Tabungan />,
    },
  ];
  const handleTabClick = (id) => {
    setCurrentLayananTab(id);
  };
  return (
    <>
      <div className="grid grid-cols-6 px-2 y-4 gap-2 md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
        {tabs.map((tab, tabIdx) => {
          return (
            <button
              disabled={currentLayananTab === tab.id}
              key={tabIdx}
              id={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`${
                currentLayananTab === tab.id ? "border-b-4" : ""
              }  text-center mx-auto hover:border-b-4 border-orange-400 py-3 xl:py-4`}
            >
              <img
                src={tab.icon}
                className="h-5 md:h-6 lg:h-7 mb-2 md:mb-3 lg:mb-4 mx-auto"
                alt=""
              />
              <p className="text-fxs md:text-fsm lg:text-fmd font-bold text-primary">
                {tab.tabTitle}
              </p>
            </button>
          );
        })}
      </div>

      {tabs.map((tab, tabIdx) => {
        return (
          <>
            <div key={tabIdx} id={tab.tabTitle}>
              {currentLayananTab === tab.id ? tab.content : ""}
            </div>
          </>
        );
      })}
    </>
  );
};

export default LainnyaTab;
