import React from "react";

import { Formik, Form } from "formik";
import CustomInput from "../../components/Form/CustomInput";
import CustomSelect from "../../components/Form/CustomSelect";
import { registSchemas } from "../../components/Form/Schemas";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import bgLanding2 from "../../images/bglanding2.png";

const Daftar = () => {
  const onSubmitHandler = async (val, actions) => {
    const formData = new FormData();
    formData.append("json", JSON.stringify(val));
    console.log(val);
  };

  return (
    <>
      <section
        className="rounded-lg py-8 h-4/5 w-11/12 mx-auto my-8 lg:my-14 xl:my-16"
        style={{
          backgroundImage: `url(${bgLanding2})`,
          backgroundPosition: "37% 63%",
          backgroundSize: "cover",
          position: "relative",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="grid grid-cols-12 py-4 bg-[#FFFBF4] md:bg-transparent	">
          <div className="hidden md:block md:col-span-6"></div>
          <div className="col-span-12 md:col-span-6">
            <div className="mb-2 text-center md:text-left">
              {/* <h6 className="font-bold text-fxs md:text-fmd text-primary mb-2">
                GABUNG SEBAGAI MITRA
              </h6> */}
              <h6 className="font-bold text-fsm md:text-fmd text-primary mb-2">
                GABUNG SEBAGAI MITRA
              </h6>

              <h1 className="hidden md:block text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Yuk, Daftar Menjadi <br />
                Agen Bistopup!
              </h1>
              <h1 className="md:hidden text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Yuk, Daftar Menjadi <br /> Agen Bistopup!
              </h1>
            </div>

            <div className="w-full md:w-11/12">
              {/*             <!-- Input Form -->
               */}{" "}
              <Formik
                initialValues={{
                  fullname: "",
                  phone: "",
                  city: "",
                  email: "",
                  address: "",
                }}
                validationSchema={registSchemas}
                onSubmit={onSubmitHandler}
                enableReinitialize={true}
              >
                <Form>
                  <div className="pt-2 md:py-2">
                    <label
                      htmlFor="email-adress-icon"
                      className="block mb-2 text-fsm md:text-fmd font-semibold"
                    >
                      Nama Lengkap
                    </label>
                    <div className="relative mt-1 text-gray-300">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          className=" lg:text-fbase xl:text-flg"
                        />
                      </div>
                      <CustomInput
                        name="fullname"
                        type="text"
                        placeholder="Masukan nama lengkap anda"
                        classInput="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                      />
                    </div>
                  </div>
                  <div className="pt-2 md:py-2">
                    <label
                      htmlFor="email-adress-icon"
                      className="block mb-2 text-fsm md:text-fmd font-semibold"
                    >
                      Nomor Whatsapp
                    </label>
                    <div className="relative mt-1 text-gray-300">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          className=" lg:text-fbase xl:text-flg"
                        />
                      </div>
                      <CustomInput
                        name="phone"
                        type="phone"
                        placeholder="Masukan whatsapp anda"
                        classInput="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                      />
                    </div>
                  </div>
                  <div className="pt-2 md:py-2">
                    <label
                      htmlFor="email-adress-icon"
                      className="block mb-2 text-fsm md:text-fmd font-semibold"
                    >
                      Kota/Kabupaten
                    </label>
                    <div className="relative mt-1 text-gray-300">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faMap}
                          className=" lg:text-fbase xl:text-flg"
                        />{" "}
                      </div>
                      <CustomSelect
                        name="city"
                        // type="city"
                        // placeholder="Masukan email anda"
                        classSelect="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                      >
                        <option defaultValue>Masukan Kota/Kabupaten</option>
                        <option value="1">Jakarta Pusat</option>
                        <option value="2">Yogyakarta</option>
                        <option value="3">Bandung</option>
                      </CustomSelect>
                    </div>
                  </div>
                  <div className="pt-2 md:py-2">
                    <label
                      htmlFor="email-adress-icon"
                      className="block mb-2 text-fsm md:text-fmd font-semibold"
                    >
                      Email
                    </label>
                    <div className="relative mt-1 text-gray-300">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          className=" lg:text-fbase xl:text-flg"
                        />{" "}
                      </div>
                      <CustomInput
                        name="email"
                        type="text"
                        placeholder="Masukan email anda"
                        classInput="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                      />
                    </div>
                  </div>
                  <div className="pt-2 md:py-2">
                    <label
                      htmlFor="email-adress-icon"
                      className="block mb-2 text-fsm md:text-fmd font-semibold"
                    >
                      Alamat
                    </label>
                    <div className="relative mt-1 text-gray-300">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className=" lg:text-fbase xl:text-flg"
                        />{" "}
                      </div>
                      <CustomInput
                        name="address"
                        type="text"
                        placeholder="Masukan alamat lengkap anda"
                        classInput="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="bg-primary shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-full rounded-lg xl:rounded-xl py-3 my-4"
                  >
                    <div className="flex items-center justify-center gap-2 md:gap-3 xl:gap-4 text-white text-fsm lg:text-fmd xl:text-fbase font-bold">
                      <h5>Daftar Jadi Agen</h5>
                      <i className="fas fa-arrow-right"></i>
                    </div>
                  </button>
                </Form>
              </Formik>
              {/* <div className="pt-2 md:py-2">
                <label
                  htmlFor="email-adress-icon"
                  className="block mb-2 text-fxs md:text-fmd font-semibold"
                >
                  Nama Lengkap
                </label>
                <div className="relative mt-1 text-gray-300">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i className="fas fa-user-circle lg:text-fbase xl:text-flg"></i>
                  </div>
                  <input
                    type="text"
                    id="email-adress-icon"
                    className="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                    placeholder="Isi nama lengkap anda"
                  />
                </div>
              </div>
              <div className="pt-2 md:py-2">
                <label
                  htmlFor="email-adress-icon"
                  className="block mb-2 text-fxs md:text-fmd font-semibold"
                >
                  No. HP/Whatsapp
                </label>
                <div className="relative mt-1 text-gray-300">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i className="fab fa-whatsapp lg:text-fbase xl:text-flg"></i>
                  </div>
                  <input
                    type="text"
                    id="email-adress-icon"
                    className="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                    placeholder="Masukan nomor HP/Whatsapp"
                  />
                </div>
              </div>
              <div className="pt-2 md:py-2">
                <label
                  htmlFor="email-adress-icon"
                  className="block mb-2 text-fxs md:text-fmd font-semibold"
                >
                  Kota/Kabupaten
                </label>
                <div className="relative mt-1 text-gray-300">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i className="far fa-map lg:text-fbase xl:text-flg"></i>
                  </div>
                  <input
                    type="text"
                    id="email-adress-icon"
                    className="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                    placeholder="Masukkan kota/kabupaten tempat anda tinggal"
                  />
                </div>
              </div>
              <div className="pt-2 md:py-2">
                <label
                  htmlFor="email-adress-icon"
                  className="block mb-2 text-fxs md:text-fmd font-semibold"
                >
                  E-Mail
                </label>
                <div className="relative mt-1 text-gray-300">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i className="far fa-envelope-open lg:text-fbase xl:text-flg"></i>
                  </div>
                  <input
                    type="text"
                    id="email-adress-icon"
                    className="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                    placeholder="Masukkan email anda"
                  />
                </div>
              </div>
              {/* ALamat domisili atau KTP ? */}
              {/* <div className="pt-2 md:py-2">
                <label
                  htmlFor="email-adress-icon"
                  className="block mb-2 text-fxs md:text-fmd font-semibold"
                >
                  Alamat lengkap
                </label>
                <div className="relative mt-1 text-gray-300">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <i className="fas fa-map-marker-alt lg:text-fbase xl:text-flg"></i>
                  </div>
                  <input
                    type="text"
                    id="email-adress-icon"
                    className="border border-gray-300 text-gray-900 text-fxs md:text-fmd rounded-lg block w-full pl-9 lg:pl-10 xl:pl-12 p-2 lg:p-3 xl:p-4 xl:placeholder:text-fbase"
                    placeholder="Tulis alamat anda dengan lengkap"
                  />
                </div>
              </div>  */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Daftar;
