import * as yup from "yup";

export const registSchemas = yup.object().shape({
  fullname: yup.string().required("nama lengkap wajib diisi"),
  email: yup
    .string()
    .email("isi email dengan benar")
    .required("email wajib diisi"),
  city: yup.number().oneOf([1, 2, 3]).required("Silahkan isi kota/kabupaten"),
  phone: yup.number().required("nomor handphone wajib diisi"),
  address: yup.string().required("alamat wajib diisi"),
});
