import React from "react";
import bgLanding3 from "../../images/bglanding3.png";
import appStoreImg from "../../images/appstore.png";
import playStoreImg from "../../images/googleplay.png";
import { Link } from "react-router-dom";

const Download = () => {
  return (
    <>
      <section className="my-8 md:my-10 lg:my-12 xl:my-16">
        <div
          className="rounded-lg"
          style={{
            minHeight: "auto",
            width: "96%",
            backgroundImage: `url(${bgLanding3})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
            backgroundRepeat: " no-repeat",
            margin: "6px auto",
          }}
        >
          <div className="flex flex-col gap-2 md:gap-4 lg:gap-5 xl:gap-6 py-36 md:py-40 xl:py-48 w-10/12 mx-auto">
            <h1 className="text-base md:text-flg lg:text-fxl xl:text-f3xl lg:leading-tight font-extrabold md:leading-tight">
              Belum Punya <br />
              Aplikasi Bisatopup?
            </h1>
            <p className="text-fxs md:text-fsm lg:text-fmd xl:text-fbase text-orange-400 font-bold">
              YUK UNDUH SEKARANG JUGA !
            </p>

            <div className="flex items-center gap-2 md:gap-3 lg:gap-6 xl:gap-8">
              {/* <a href="https://berbagi.link/bisatopup/download" target="_blank">
                <img
                  src={appStoreImg}
                  className="h-7 md:h-9 lg:h-11 xl:h-14"
                  alt=""
                />
              </a> */}
              <a href="https://berbagi.link/bisatopup/download" target="_blank">
                <img
                  src={playStoreImg}
                  className="h-7 md:h-9 lg:h-11 xl:h-14"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
