import React from "react";
import img from "../../images/content6.png";

const Keunggulan = () => {
  return (
    <>
      <section className="my-8 lg:my-14 xl:my-16 relative overflow-hidden">
        <div className="text-center mb-7 md:mb-12 lg:mb-16 xl:mb-24">
          <h6 className="font-bold text-fsm md:text-fmd text-primary mb-2">
            KEUNGGULAN
          </h6>
          <h1 className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
            Keunggulan-keunggulan <br />
            Aplikasi Bisatopup
          </h1>
          <p className="text-fmd xl:text-fbase text-gray-400 hidden md:block my-3">
            Bisatopup memiliki banyak keunggulan yang akan membuat <br />
            transaksi anda semakin mudah, aman, dan nyaman.
          </p>
        </div>
        <div className="w-10/12 mx-auto">
          <div className="flex flex-col gap-5 md:gap-8 lg:gap-11 xl:gap-14">
            <div>
              <h5 className="text-fsm md:text-fbase lg:text-flg xl:text-fxl font-semibold mb-2 xl:mb-4">
                Produk Anak Negeri
              </h5>
              <p className="text-fxs md:text-fmd lg:text-fbase xl:text-flg font-light text-gray-500">
                Sejak dimulai dari tahun 2014 alhamdulillah <br />
                bisatopup dipercaya sebagai aplikasi PPOB <br />
                produk neger paling nyaman dan aman <br />
                digunakan.
              </p>
            </div>

            <div>
              <h5 className="text-fsm md:text-fbase lg:text-flg xl:text-fxl font-semibold mb-2 xl:mb-4">
                Bebas Biaya Pemeliharaan
              </h5>
              <p className="text-fxs md:text-fmd lg:text-fbase xl:text-flg font-light text-gray-500">
                Hanya bermodalkan smartphone dan koneksi <br />
                internet anda dapat melakukan transaksi <br />
                tanpa harus pusing dengan biaya <br />
                pemeliharaan berlebih dalam usaha anda.
              </p>
            </div>

            <div>
              <h5 className="text-fsm md:text-fbase lg:text-flg xl:text-fxl font-semibold mb-2 xl:mb-4">
                Amanah & Sesuai Syariat
              </h5>
              <p className="text-fxs md:text-fmd lg:text-fbase xl:text-flg font-light text-gray-500">
                Sesuai dengan nama perusahaan kami, maka <br />
                kami terus berkomitmen untuk memberikan <br />
                solusi bisnis yang terbaik untuk para pengguna <br />
                dengan amanah dan sesuai syariat. InsyaAllah
              </p>
            </div>

            <div>
              <h5 className="text-fsm md:text-fbase lg:text-flg xl:text-fxl font-semibold mb-2 xl:mb-4">
                Harga Terjangkau
              </h5>
              <p className="text-fxs md:text-fmd lg:text-fbase xl:text-flg font-light text-gray-500">
                Kami menyediakan layanan aplikasi dan <br />
                stock produk dengan harga yang <br />
                terjangkau dan bersaing.
              </p>
            </div>
          </div>
          {/* <div className="flex justify-start">
            <button className="bg-primary shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-5/12 md:w-3/12 rounded-xl xl:rounded-2xl py-3 md:py-4 my-10">
              <div className="flex items-center justify-center gap-2 md:gap-3 xl:gap-4 text-white text-fsm lg:text-fmd xl:text-fbase font-bold">
                <h5>Baca Selanjutnya</h5>
                <i className="fas fa-arrow-right"></i>
              </div>
            </button>
          </div> */}
        </div>
        <div className="absolute w-4/12 top-28 md:top-38 right-0">
          <img src={img} alt="" />
        </div>
      </section>
    </>
  );
};

export default Keunggulan;
