import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

import icon12 from "../../images/icon12.png";
import icon13 from "../../images/icon13.png";
import icon14 from "../../images/icon14.png";
import icon15 from "../../images/icon15.png";
import content9 from "../../images/content9.png";
import eta from "../../images/eta.png";
import bsi from "../../images/bsi.png";
import kominfo from "../../images/kominfo.png";

import jumbotronImg from "../../images/content9.png";
import bgtentang1 from "../../images/bgtentang1.png";

const Faq = () => {
  return (
    <>
      <Navbar active="terms-condition" />

      <section className="header">
        <div className="py-4 md:py-0">
          <section className="w-11/12 lg:w-10/12 mx-auto">
            <div className="md:grid grid-cols-12">
              <div className="md:hidden">
                <img src={jumbotronImg} alt="" />
              </div>
            </div>
          </section>

          <div className="hidden md:grid items-center grid-cols-12 w-11/12  mx-auto  gap-4">
            <div className="col-span-1"></div>
            <div className="col-span-5 flex flex-col gap-3 lg:gap-4">
              <h1 className="text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Syarat & Ketentuan
              </h1>

              <p className="text-fsm lg:text-fmd xl:text-fbase font-light text-gray-400">
                Produk platform Payment Point Online Bank <br /> (PPOB) berbasis
                aplikasi android buatan{" "}
                <span className="font-bold text-gray-600">
                  PT. <br /> Bisatopup Teknologi Indonesia
                </span>{" "}
                yang terbentuk <br /> dari tahun 2014 hingga sekarang.
              </p>
            </div>

            <div className="col-span-6 p-0">
              <img src={content9} className="w-full p-0" alt="" />
            </div>
          </div>
        </div>

        <div className="md:hidden w-10/12 mx-auto">
          <div className="w-11/12 flex flex-col gap-2 py-4">
            <h1 className="text-flg font-bold leading-tight">
              {" "}
              Kami adalah <br />
              <span className="text-primary">Bisatopup.</span>{" "}
            </h1>

            <p className="text-fsm text-gray-400">
              Produk platform Payment Point Online Bank <br /> (PPOB) berbasis
              aplikasi android buatan PT. <br /> Bisatopup Teknologi Indonesia
              yang terbentuk <br /> dari tahun 2014 hingga sekarang.
            </p>
          </div>
        </div>
      </section>

      <section className="content my-10">
        <div className="w-11/12 lg:w-10/12 mx-auto px-12 py-8 lg:px-24 lg:py-16 border rounded overflow-y-auto max-h-[600px]">
          <div className="syarat-ketentuan">
            <div className="judul text-fbase lg:text-fxl font-bold  text-center my-4">
              *Syarat dan Ketentuan*
            </div>
            <div className="isi text-fsm lg:text-fmd text-justify">
              <div>
                Syarat & Ketentuan yang ditetapkan di bawah ini mengatur terkait
                penggunaan aplikasi yang ditawarkan oleh PT. Bisatopup Teknologi
                Indonesia yaitu aplikasi Bisatopup. Pengguna disarankan membaca
                dengan seksama karena dapat berdampak kepada hak dan kewajiban
                Pengguna di bawah hukum.
                <br /> <br /> Dengan mendaftar dan/atau menggunakan aplikasi
                Bisatopup, maka pengguna dianggap telah membaca, mengerti,
                memahami dan menyetujui semua isi dalam Syarat & Ketentuan.
                Syarat & Ketentuan ini merupakan bentuk kesepakatan yang
                dituangkan dalam sebuah perjanjian yang sah antara Pengguna
                dengan PT. Bisatopup Teknologi Indonesia. Jika Pengguna tidak
                menyetujui salah satu, sebagian, atau seluruh isi Syarat &
                Ketentuan, maka Pengguna tidak diperkenankan menggunakan layanan
                aplikasi Bisatopup.
              </div>
              <div className="definisi my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Definisi
                </div>
                <div className="isi">
                  Semua istilah yang didefinisikan dan ditekankan dalam Syarat &
                  Ketentuan ini memiliki makna tertentu, sesuai dengan
                  penjelasan di bawah ini:
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      atopup adalah aplikasi yang Pengguna unduh (download)
                      sehingga Pengguna masuk pada Laman ini atau Syarat &
                      Ketentuan, yang saat ini dikenal dengan merek, nama, logo
                      dan/atau tanda yang dikenal dengan “Bisatopup” atau merek,
                      nama, logo dan/atau tanda lainnya dan hanya dapat
                      didownload pada perangkat Android.
                    </li>
                    <li>
                      Pengguna adalah pihak yang menggunakan layanan aplikasi
                      Bisatopup, termasuk namun tidak terbatas pada pembeli,
                      penjual, dan pihak lain yang memiliki kepentingan
                      menggunakan aplikasi Bisatopup.
                    </li>
                    <li>
                      Akun mengacu pada Akun yang dibuat oleh Pengguna setelah
                      Pengguna menyelesaikan proses pendaftaran di aplikasi
                      Bisatopup, yang berisi informasi mengenai nomor telepon
                      Pengguna dan Saldo Bisatopup milik Pengguna.
                    </li>
                    <li>
                      E-Wallet, Saldo, atau deposit berarti alat pembayaran yang
                      dikeluarkan oleh Bisatopup, yang dapat digunakan oleh para
                      Pengguna untuk membeli produk dan layanan dari Partner
                      atau untuk melakukan transaksi keuangan tertentu.
                    </li>
                    <li>Bisatopup adalah PT Bisatopup Teknologi Indonesia.</li>
                    <li>
                      Layanan berarti setiap layanan, program, jasa, produk,
                      fitur, sistem, fasilitas dan/atau jasa yang disediakan
                      dan/atau ditawarkan dalam atau melalui aplikasi atau web
                      Bisatopup.
                    </li>
                    <li>
                      Layanan Pelanggan adalah fungsi customer service center
                      untuk Pengguna yang dapat dihubungi lewat panggilan
                      telepon, chat dan/atau email.
                    </li>
                    <li>
                      Syarat Ketentuan berarti Syarat & Ketentuan ini berikut
                      setiap perubahan, penambahan, penggantian, penyesuaian
                      dan/atau modifikasinya yang dibuat dari waktu ke waktu.
                    </li>
                    <li>
                      Transaksi berarti segala transaksi, kegiatan, aktivitas
                      dan/atau aksi yang dilakukan dalam atau melalui
                      aplikasi/akun, termasuk penggunaan layanan atau
                      fitur-fitur tertentu dalam aplikasi.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="ketentuan-umum my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Ketentuan Umum
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Setiap Syarat & Ketentuan yang berlaku untuk subjek
                      maskulin juga berlaku untuk subjek feminin dan sebaliknya,
                      kecuali diatur dan dijelaskan secara terpisah dalam Syarat
                      & Ketentuan ini.
                    </li>
                    <li>
                      Judul dan keterangan hanya digunakan untuk membantu
                      memperjelas Syarat & Ketentuan ini sehingga tidak
                      seharusnya mempengaruhi pengertian dari Syarat & Ketentuan
                      ini.
                    </li>
                    <li>
                      Setiap Syarat & Ketentuan yang berlaku untuk suatu
                      individu, kecuali dicatat dan dijelaskan lain, juga akan
                      berlaku untuk pewaris, wali dan perwakilan resmi dari
                      individu tersebut. Demikian pula, setiap Syarat &
                      Ketentuan yang berlaku untuk suatu entitas hukum, misalnya
                      Bisatopup, kecuali dicatat dan dijelaskan lain, juga akan
                      berlaku untuk rekanan, pewaris dan perwakilan resmi dari
                      entitas tersebut.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="daftar my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Pendaftaran Akun
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Anda dapat membuat dan menggunakan akun Bisatopup jika
                      Anda:
                      <ol className="list-none space-y-2">
                        <li>
                          a. Minimal berusia 17 tahun bagi akun Agen
                          Premiun/verified
                        </li>
                        <li>b. Mempunyai E-KTP Indonesia</li>
                        <li>
                          c. Tinggal di Indonesia dan memiliki alamat tinggal
                          yang valid
                        </li>
                        <li>
                          d. Menggunakan layanan Bisatopup untuk melakukan
                          transaksi hanya dalam Rupiah Indonesia dan bukan dalam
                          mata uang lainnya
                        </li>
                        <li>
                          e. Menggunakan Layanan di perangkat internet yang
                          sesuai.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Anda hanya dapat menggunakan layanan Bisatopup jika Anda
                      memenuhi Poin Nomor 1 di atas. Jika Anda tidak memenuhi
                      ketentuan tersebut, Anda dimohon untuk membatalkan upaya
                      apapun untuk membuat dan menggunakan akun Bisatopup.
                    </li>
                    <li>
                      Bisatopup berhak untuk segera membekukan akun Anda jika
                      Bisatopup memiliki alasan bahwa akun Anda telah digunakan
                      oleh orang yang tidak bertanggung jawab.
                    </li>
                    <li>
                      Registrasi dan kepemilikan akun Bisatopup tidak dikenakan
                      biaya apapun.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="informasi-akun my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Informasi Akun
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Untuk membuat sebuah akun, Anda harus memberikan data
                      registrasi berikut kepada Bisatopup:
                      <ol className="list-none space-y-2">
                        <li>a. Nama lengkap Anda (sesuai identitas)</li>
                        <li>b. Nomor handphone yang valid dan fungsional</li>
                        <li>c. Alamat e-mail yang valid dan fungsional</li>
                        <li>
                          d. Informasi tambahan yang diperlukan untuk memenuhi
                          regulasi yang berlaku dan mendukung pelaksanaan
                          layanan Bisatopup.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Data Registrasi dan informasi tambahan lainnya yang Anda
                      berikan kepada Bisatopup harus disampaikan dengan
                      sebenar-benarnya, sesuai dengan pengetahuan Anda,
                      kepercayaan Anda dan kenyataan yang sebenarnya. Kesalahan
                      dalam pemberian Data Registrasi akan sepenuhnya menjadi
                      tanggung jawab Anda sendiri.
                    </li>
                    <li>
                      Anda dilarang membagikan Data Registrasi Anda kepada pihak
                      ketiga mana pun. Anda bertanggung jawab sepenuhnya untuk
                      menjaga kerahasiaan Data Registrasi Anda dan bertanggung
                      jawab atas setiap aktivitas dan transaksi yang terjadi
                      melalui Akun Bisatopup milik Anda, baik yang dilakukan
                      oleh Anda atau pihak ketiga mana pun.
                    </li>
                    <li>
                      Pihak Bisatopup tidak pernah meminta password/pin/kode OTP
                      Anda untuk alasan apapun, baik melalui sms, telepon, atau
                      media lainnya. Anda diwajibkan untuk menjaga kerahasiaan
                      password/pin/kode OTP Anda dari pihak manapun, termasuk
                      dari semua pihak Bistaopup sekalipun. Jika
                      password/pin/kode OTP Anda diketahui oleh pihak lain dan
                      karenanya terjadi penyalahgunaan akun Anda, hal tersebut
                      bukan menjadi tanggung jawab Bisatopup.
                    </li>
                    <li>
                      Apabila Anda membutuhkan bantuan terkait keamanan dan
                      perlindungan Akun Anda, Bisatopup menghimbau Anda untuk:
                      <ol className="list-none space-y-2">
                        <li>
                          a. Segera hubungi Bisatopup di support@bisatopup.co.id
                          nomor telepon Bisatopup di 0816 266 755 mengenai apa
                          pun yang berkaitan dengan Penggunaan secara ilegal
                          atau tanpa izin atas Akun Anda
                        </li>
                        <li>b. Mengubah PIN Anda secara berkala</li>
                      </ol>
                    </li>
                    <li>
                      Untuk mencegah pencucian uang dan/atau pembiayaan
                      terorisme di Indonesia, Bisatopup berhak untuk membekukan
                      Akun Anda dengan segera jika Bisatopup memiliki alasan
                      untuk mempercayai bahwa Data Registrasi atau data apa pun
                      yang diberikan oleh Anda tidak benar, dan/atau ada
                      indikasi bahwa Akun Anda telah dibajak dengan cara apa
                      pun.
                    </li>
                    <li>
                      Jika Anda lupa password, Anda dapat menghubungi CS dan
                      mendapatkan password baru dengan melakukan verifikasi data
                      Anda terlebih dahulu. Jika password Anda diketahui oleh
                      pihak lain dan karenanya terjadi penyalahgunaan atas akun
                      Anda, hal tersebut bukan menjadi tanggung jawab Bisatopup.
                    </li>
                    <li>
                      Jika Anda tidak dapat mengakses Akun Anda karena alasan
                      lain selain lupa Password Anda, mohon segera hubungi
                      Bisatopup di 0816 266 755 dan buat permintaan untuk
                      membekukan Akun Anda. Bisatopup tidak akan bertanggung
                      jawab atas transaksi tanpa izin/ilegal apa pun yang
                      dilakukan dengan menggunakan Akun Anda sebelum Anda
                      membuat permintaan untuk membekukan Akun Anda.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="batasan-saldo my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Batasan Saldo dan Transaksi
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Jumlah saldo Bisatopup Anda dibatasi sebesar: (a) Maksimal
                      Rp. 2.000.000,- (dua juta Rupiah) jika akun Anda adalah
                      Bisatopup jenis Unverified; atau (b) Rp. Maksimal
                      10.000.000,- (sepuluh juta Rupiah) jika akun Anda adalah
                      Bisatopup jenis Verified.
                    </li>
                    <li>
                      Jika saldo atau transaksi bulanan akun Bisatopup Anda
                      melewati batas tersebut, maka Bisatopup berhak melakukan
                      verifikasi sebelum melakukan tindakan lebih lanjut,
                      termasuk namun tidak terbatas pada tidak menjalankan
                      perintah transaksi yang Anda sampaikan.
                    </li>
                    <li>
                      Bisatopup perlu Anda memahami bahwa Bisatopup bukan bank.
                      Saldo akun Bisatopup Anda bukan merupakan tabungan
                      berdasarkan pengertian hukum yang berkaitan dengan
                      perbankan, tidak tunduk pada program perlindungan oleh
                      Lembaga Penjamin Simpanan dan tidak berhak atas setiap
                      fitur yang umumnya melekat pada rekening bank (seperti
                      bunga, bagi hasil, dsb).
                    </li>
                  </ol>
                </div>
              </div>
              <div className="penggunaan-layanan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Penggunaan Layanan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Bisatopup dapat digunakan untuk layanan yang tersedia baik
                      di aplikasi Bisatopup maupun layanan lain di luar aplikasi
                      Bisatopup, sebagaimana Bisatopup informasikan.
                    </li>
                    <li>
                      Transaksi menggunakan Bisatopup dapat dilakukan dengan
                      pelaku usaha yang merupakan pihak ketiga yang memiliki
                      perjanjian kerja sama dengan Bisatopup dan/atau perusahaan
                      terafiliasi dengan Bisatopup.
                    </li>
                    <li>
                      Saldo Bisatopup Anda dapat digunakan atau ditransaksikan
                      seluruhnya sampai bersaldo nihil sesuai dengan syarat dan
                      ketentuan ini.
                    </li>{" "}
                    <li>
                      Pelaku usaha yang merupakan pihak ketiga yang memiliki
                      perjanjian kerja sama dengan Bisatopup yang dapat menerima
                      pembayaran menggunakan Saldo Bisatopup dilarang mengenakan
                      biaya tambahan (surcharge) kepada Anda sebagai pengguna
                      atas pembayaran transaksi pembelian/pembelanjaan
                      menggunakan Saldo Bisatopup. Jika Anda menemukan pelaku
                      usaha yang mengenakan biaya tambahan (surcharge) kepada
                      Anda sebagai pengguna atas pembayaran transaksi
                      pembelanjaan menggunakan Saldo Bisatopup, silahkan hubungi
                      Bisatopup di support@bisatopup.coid
                    </li>{" "}
                    <li>
                      Cara penggunaan dan/atau metode pembayaran dapat berubah
                      dan/atau bertambah dari waktu ke waktu, sebagaimana
                      Bisatopup informasikan. Informasi tersebut akan Bisatopup
                      sampaikan terlebih dahulu kepada Pengguna melalui
                      cara–cara yang akan Bisatopup tetapkan.
                    </li>{" "}
                    <li>
                      Saldo Bisatopup Anda akan dikurangi secara otomatis dengan
                      jumlah yang dimasukkan atau dikonfirmasi oleh Anda untuk
                      pembayaran terkait, selama Bisatopup Anda mencukupi. Jika
                      Saldo Bisatopup Anda tidak mencukupi pembayaran terkait,
                      Bisatopup dapat menolak memproses pembayaran Anda atau
                      jika layanan Bisatopup memungkinkan pembayaran sebagian
                      (partial payment), maka Anda akan diwajibkan membayar
                      selisihnya menggunakan uang tunai atau cara lainnya yang
                      Bisatopup tentukan.
                    </li>
                    <li>
                      Transaksi yang telah Anda konfirmasi dan Bisatopup proses,
                      tidak dapat dibatalkan. Bermakna anda telah setuju dengan
                      syarat dan ketentuan yang telah Bisatopup tentukan dan
                      pembatalan hanya dapat dilakukan apabila terbukti
                      kesalahan ada di Pihak Bisatopup.
                    </li>
                    <li>
                      Anda memahami bahwa Bisatopup merupakan penyelenggara jasa
                      sistem pembayaran. Setiap keluhan dan/atau klaim terkait
                      barang dan/atau jasa yang disediakan oleh pihak ketiga
                      wajib Anda sampaikan langsung kepada pihak ketiga terkait.
                    </li>
                    <li>
                      Perlu Anda mengerti bahwa penggunaan akun Bisatopup Anda
                      dan/atau layanan Bisatopup merupakan tanggung jawab
                      pribadi Anda. JANGAN MEMBERI AKSES ATAU MEMBIARKAN ORANG
                      LAIN UNTUK MENGAKSES AKUN Bisatopup Anda. Anda setuju
                      bahwa setiap perintah transaksi yang dibuat melalui akun
                      Bisatopup Anda akan dianggap telah diotorisasi oleh Anda
                      atau berdasarkan perintah/persetujuan Anda dan oleh
                      karenanya adalah perintah transaksi Anda. Bisatopup tidak
                      bisa dan tidak akan bisa dimintakan pertanggungjawaban
                      atas setiap kerugian atau klaim yang timbul karena
                      penggunaan akun Bisatopup anda dan/atau layanan Bisatopup
                      yang dilakukan dengan melanggar Syarat dan Ketentuan ini.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="layanan-kereta my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Layanan Kereta Api
                </div>
                <div className="isi">
                  <h3>Berikut informasi perjalanan naik kereta api  PT KAI yang berlaku 12 Juni 2023 hingga seterusnya!</h3>
                  <ol className="list-decimal list-outside space-y-2 mt-2">
                    <li>
                      Penumpang KA tidak wajib menunjukkan kartu/sertifikat vaksin
                      <div>
                        - Penumpang kereta api jarak jauh kini tidak wajib lagi menunjukkan kartu/sertifikat vaksin COVID-19. Namun, penumpang kereta api tetap dianjurkan untuk mengikuti vaksinasi COVID-19 hingga dosis booster ke-2 atau dosis ke-4, terutama bagi masyarakat dengan risiko tinggi penularan COVID-19.
                      </div>
                    </li>
                    <li>
                      Tidak perlu membawa hasil tes COVID-19
                      <div>
                        - Penumpang kereta api jarak jauh kini juga tidak perlu menunjukkan hasil negatif tes COVID-19 apapun, baik rapid antigen maupun PCR.
                      </div>
                    </li>
                    <li>
                      Penumpang dalam kondisi sehat
                      <div>
                        - Penumpang kereta api harus dalam kondisi sehat saat akan berangkat dan tidak menderita flu, batuk, atau demam.
                      </div>
                    </li>
                    <li>
                      Tidak wajib pakai masker
                      <div>
                        - Penumpang kereta api diperbolehkan tidak memakai masker, apabila dalam keadaan sehat dan tidak berisiko tertular maupun menularkan COVID-19.
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <div className="pembatalan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Pembatalan Transaksi dan Refund
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Bisatopup sangat menyarankan Anda untuk selalu mengecek
                      detil setiap isi ulang ataupun transfer uang Anda dengan
                      seksama sebelum bertransaksi. Dengan demikian, tidak akan
                      ada kesalahan transaksi sehingga Anda tidak perlu
                      membatalkan transaksi yang telah dilakukan. Perlu dicatat
                      bahwa transaksi hanya dapat dibatalkan apabila belum
                      diproses.
                    </li>
                    <li>
                      Jika Anda menginginkan refund sejumlah uang tertentu yang
                      telah dipotong dari Akun Bisatopup Anda, mohon jelaskan
                      permintaan refund Anda ke customer service Bisatopup di
                      0816 266 755
                    </li>
                    <li>
                      Setiap permintaan refund yang dibuat oleh Anda akan
                      diproses oleh Bisatopup, keberhasilan dari permintaan
                      tersebut bergantung pada validasi sesuai prosedur
                      Bisatopup.
                    </li>
                    <li>
                      Jika Bisatopup memutuskan bahwa permintaan refund Anda
                      valid, Bisatopup akan mengupayakan yang terbaik untuk
                      melaksanakan refund tersebut dan mengembalikan saldo yang
                      berkaitan ke Akun Bisatopup Anda dalam waktu maksimal 5
                      hari kerja terhitung setelah laporan diterima.
                    </li>
                    <li>
                      Bisatopup akan selalu mengupayakan yang terbaik untuk
                      menjawab permintaan refund Anda secepat mungkin. Bisatopup
                      tidak bertanggung jawab atas penundaan/keterlambatan
                      proses refund, yang mungkin disebabkan oleh Anda maupun
                      pihak ketiga, seperti contohnya Bank.
                    </li>
                  </ol>
                </div>
              </div>{" "}
              <div className="mencurigakan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Transaksi Mencurigakan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Jika Bisatopup memiliki alasan untuk mempercayai adanya
                      indikasi penipuan dalam aktivitas pembayaran yang terjadi
                      atas akun Anda, Bisatopup akan membekukan akun Anda. Anda
                      akan kehilangan akses ke dalam akun Anda selama Bisatopup
                      melakukan proses investigasi.
                    </li>
                    <li>
                      Jika Anda menginginkan refund sejumlah uang tertentu yang
                      telah dipotong dari Akun Bisatopup Anda, mohon jelaskan
                      permintaan refund Anda ke customer service Bisatopup di
                      0816 266 755
                    </li>
                    <li>
                      Bisatopup bergantung pada sejumlah pihak ketiga seperti
                      Bank dan Partner dalam melakukan investigasi. Namun,
                      Bisatopup akan mengupayakan yang terbaik untuk
                      menyelesaikan investigasi tersebut secepat mungkin.
                    </li>
                    <li>
                      Jika Bisatopup memutuskan bahwa permintaan refund Anda
                      valid, Bisatopup akan mengupayakan yang terbaik untuk
                      melaksanakan refund tersebut dan mengembalikan saldo yang
                      berkaitan ke Akun Bisatopup Anda dalam waktu maksimal 5
                      hari kerja terhitung setelah laporan diterima.
                    </li>
                    <li>
                      Kembalinya hak akses Anda atas akun Anda bergantung pada
                      hasil investigasi Bisatopup dan/atau pihak ketiga.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="tautan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Tautan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Situs resmi Bisatopup tersedia di www.bisatopup.co.id.
                      Anda akan melihat bahwa URL untuk setiap halaman dari
                      situs resmi Bisatopup diawali dengan “https://”. Dengan
                      kata lain, setiap halaman dari situs resmi Bisatopup
                      merupakan hypertext transfer protocol secure (“Berbasis
                      HTTPS”) atau “Identitas Terverifikasi”.
                    </li>
                    <li>
                      HTTPS dari seluruh situs resmi Bisatopup telah diaktifkan
                      untuk mengamankan Anda dari situs-situs tiruan. Pastikan
                      halaman situs yang Anda buka berbasis HTTPS sebelum
                      memberikan informasi apapun. Bisatopup tidak akan
                      bertanggung jawab untuk akibat apapun yang terjadi jika
                      Anda memberikan informasi di situs pihak ketiga.
                    </li>
                    <li>
                      Bisatopup tidak menjamin keamanan, fungsionalitas atau
                      aksesibilitas dari situs pihak ketiga, termasuk
                      kasus-kasus di mana tautan ke situs pihak ketiga tersedia
                      di situs resmi Bisatopup.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="waktu-layanan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Waktu Layanan
                </div>
                <div className="isi">
                  Layanan Bisatopup bergantung pada pihak ketiga seperti bank,
                  perusahaan rekanan, dan lainnya. Bisatopup tidak dapat
                  mengontrol kejadian yang berhubungan dengan layanan dari pihak
                  ketiga. Oleh karena itu, Bisatopup tidak dapat bertanggung
                  jawab untuk menjamin keberhasilan atau kecepatan layanan
                  pembayaran di waktu-waktu khusus sebagai berikut:
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Waktu di luar jam operasional normal di Indonesia
                      (Senin–Jumat, pukul 09:00 – 18:00 WIB)
                    </li>
                    <li>
                      Hari Sabtu dan Minggu yang dianggap sebagai hari libur
                      akhir pekan di Indonesia
                    </li>
                    <li>
                      Hari-hari yang dianggap sebagai hari libur nasional di
                      Indonesia
                    </li>
                    <li>Hari-hari di mana kasus “Force Majeure” terjadi</li>
                  </ol>
                </div>
              </div>
              <div className="pemblokiran-akun my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Pemblokiran Akun
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Atas permintaan Anda, Bisatopup bisa dan boleh memblokir
                      akun Bisatopup Anda. Selain itu, jika menurut pertimbangan
                      Bisatopup terjadi hal-hal yang merugikan dan/atau dapat
                      merugikan Bisatopup atau Anda, Bisatopup juga berhak
                      melakukan pemblokiran tersebut tanpa permintaan dari Anda.
                      Namun demikian, Bisatopup menyediakan jalur pengajuan
                      keluhan bagi Anda.
                    </li>
                    <li>
                      Pemblokiran akun dapat terjadi termasuk, namun tidak
                      terbatas pada, kejadian-kejadian berikut ini:
                      <ol className="list-none space-y-2">
                        <li>
                          a. Laporan Anda bahwa telepon selular Anda hilang atau
                          dicuri atau diretas;
                        </li>
                        <li>
                          b. Perintah pemblokiran yang diterbitkan oleh
                          institusi pemerintah atau moneter atau aparat penegak
                          hukum berdasarkan perintah pengadilan;
                        </li>
                        <li>
                          c. Akun Bisatopup tidak digunakan untuk jangka waktu
                          minimum selama 9 bulan berturut-turut;
                        </li>
                        <li>
                          d. Penggunaan akun Bisatopup dan/atau layanan
                          Bisatopup Anda dengan cara yang bertentangan dengan
                          Syarat & Ketentuan;
                        </li>
                        <li>
                          e. Alasan lain yang merugikan Anda atau Bisatopup,
                          termasuk tindakan abusive dan fraud atau ketika sistem
                          Bisatopup menangkap pola transaksi keuangan yang
                          mencurigakan.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Jika akun Bisatopup Anda diblokir dan Anda memiliki bukti
                      yang kuat bahwa tidak ada hal mencurigakan yang terjadi,
                      Anda dapat mengajukan keluhan Anda sesuai dengan yang
                      tertera pada Syarat & Ketentuan ini. Setelah memeriksa
                      keluhan Anda, Bisatopup dapat menentukan untuk mengakhiri
                      atau melanjutkan pemblokiran akun Bisatopup Anda.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="penutupan-akun my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Penutupan Akun dan Masa Kaduluarsa
                </div>
                <div className="isi">
                  Bisatopup berhak untuk mengubah, memberhentikan atau menutup
                  layanan untuk Anda jika terjadi perubahan regulasi dan
                  hukum-hukum yang berlaku di Indonesia.
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Anda dapat meminta pemberhentian layanan (blokir) di
                      setiap waktu dengan mengirimkan permintaan melalui CS
                      Bisatopup. Bisatopup akan mengupayakan yang terbaik untuk
                      menjawab permintaan pemberhentian layanan Anda secepat
                      mungkin.
                    </li>
                    <li>
                      Anda akan tetap bertanggung jawab atas semua transaksi
                      yang terjadi sebelum pemberhentian Akun Anda.
                    </li>
                    <li>
                      3. Bisatopup akan mengembalikan saldo ewallet Bisatopup
                      Anda melalui metode yang disepakati bersama antara para
                      pihak, setelah dikurangi dengan biaya yang terutang (jika
                      ada), kecuali jika berdasarkan pertimbangan Bisatopup
                      diperlukan adanya penahanan bagian tertentu dari saldo
                      ewallet Bisatopup Anda, misalnya karena keyakinan yang
                      wajar bahwa bagian tersebut berhubungan dengan atau
                      dihasilkan dari penipuan dan/atau tindak pelanggaran
                      hukum.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="peringatan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Peringatan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Kecuali untuk peran terbatas Bisatopup dalam memproses
                      pembayaran yang Anda izinkan atau ajukan, Bisatopup tidak
                      terlibat dalam transaksi antara Anda, Pengguna lainnya,
                      Bank, atau Pihak Ketiga lainnya.
                    </li>
                    <li>
                      Jika ada pihak lain menggunakan nama Bisatopup atau
                      menggunakan rekening bank atas nama Bisatopup untuk
                      meminta pembayaran/transfer, Anda TIDAK BOLEH
                      MEMPERCAYAINYA/MELAKUKANNYA. Bisatopup tidak akan
                      bertanggung jawab jika Anda mempercayai/melakukan
                      pembayaran/transfer atas permintaan dari pihak lain
                      tersebut. Jika Anda mengalami kejadian ini, MOHON
                      MELAPORKANNYA kepada Bisatopup melalui kontak Bisatopup
                      di:
                      <ol className="list-none space-y-2">
                        <li>a. Nomor handphone : 0816 266 755</li>
                        <li>b. Email CS : support@bisatopup.co.id </li>
                        <li>
                          c. Atau dapat dilihat di halaman kontak Bisatopup
                        </li>
                      </ol>
                    </li>
                    <li>
                      Bisatopup bukan agen penjualan yang bertanggung jawab atas
                      penjualan barang atau jasa apa pun selain dari yang
                      tertera di aplikasi Bisatopup oleh siapa pun kepada Anda.
                      Bisatopup tidak memiliki kontrol atas kualitas,
                      kesesuaian, keamanan, keandalan, keabsahan, atau aspek
                      lainnya dari produk atau layanan yang mungkin Anda beli
                      menggunakan layanan Bisatopup atau situs pihak ketiga apa
                      pun yang mungkin Anda gunakan untuk mengakses layanan
                      tersebut.
                    </li>
                    <li>
                      {" "}
                      Bisatopup tidak menjamin terkait Penggunaan atau hasil
                      dari layanan dalam hal kesesuaian, kebenaran, akurasi dan
                      keandalan. Anda bertanggung jawab penuh atas resiko dari
                      akses dan penggunaan layanan oleh Anda.
                    </li>
                    <li>
                      Bisatopup tidak bertanggung jawab atas segala saran
                      dan/atau informasi, baik lisan maupun tertulis, mengenai
                      jaminan apapun yang Anda dapatkan dari pihak ketiga atau
                      pihak selain Bisatopup{" "}
                    </li>
                    <li>
                      Bisatopup akan mengupayakan yang terbaik untuk tidak
                      membagikan Detil Pembayaran apa pun, Data Registrasi atau
                      Informasi Personal lainnya yang Anda berikan kepada
                      Bisatopup, kecuali jika diharuskan oleh regulasi dan
                      hukum-hukum yang berlaku, atau hingga batasan tertentu
                      Bisatopup perlu melaksanakan instruksi pembayaran yang
                      Bisatopup terima dari Anda. Bisatopup tidak bertanggung
                      jawab untuk segala akibat yang terjadi jika Informasi
                      Personal Anda telah secara sukarela atau secara tidak
                      sengaja dibagikan oleh Anda atau oleh pihak lainnya ke
                      pihak ketiga apa pun.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="larangan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Larangan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Pengguna tidak akan menyadap atau mengganggu Pengguna
                      lainnya
                    </li>
                    <li>
                      Pengguna tidak dan tidak akan melanggar kekayaan
                      intelektual, rahasia dagang, hak-hak kepemilikan
                    </li>
                    <li>
                      Pengguna tidak akan memfitnah, mengancam atau melecehkan
                      dalam berkomunikasi dengan Bisatopup atau Pengguna lainnya
                    </li>
                    <li>
                      Pengguna akan berlaku sopan dan tidak cabul dalam
                      berkomunikasi dengan Bisatopup atau Pengguna lainnya
                    </li>
                    <li>
                      {" "}
                      Pengguna tidak akan menghasut, menghina, mengasari, atau
                      memicu masalah ras, etnik atau kebencian agama,
                      diskriminasi, ancaman, penganiayaan, skandal, hasutan atau
                      hujatan dalam berkomunikasi dengan Bisatopup atau Pengguna
                      lainnya{" "}
                    </li>
                    <li>
                      Pengguna tidak akan mengangkat dan/atau memicu perilaku
                      yang dapat dianggap tindakan kriminal, mengakibatkan
                      tanggung jawab perdata, atau bertentangan dengan hukum,
                    </li>
                    <li>
                      {" "}
                      Pengguna tidak akan menyebarkan material yang berbahaya
                      (meliputi tanpa kecuali virus komputer/handphone, logic
                      bombs, Trojan, worms, komponen-komponen berbahaya, data
                      rusak atau perangkat lunak berbahaya lainnya atau data
                      berbahaya atau bentuk kode lainnya, atau files) atau rutin
                      pemrograman lainnya yang dapat merusak, menghancurkan,
                      membatasi, menghambat, mengganggu, menghilangkan fungsi,
                      mencegat atau mengambil alih fungsi dari sistem, data atau
                      informasi personal{" "}
                    </li>
                    <li>
                      Pengguna tidak akan membuat Bisatopup bertanggung jawab
                      atau merugikan Bisatopup atas kehilangan (seluruh atau
                      sebagian) kepemilikan atau bisnis.
                    </li>
                    <li>
                      {" "}
                      Komunikasi Pengguna dengan Bisatopup atau Pengguna lainnya
                      tidak akan bersifat kampanye politik, iklan yang tidak
                      diminta atau tanpa izin, ajakan promosional dan/atau
                      komersial, tawaran MLM, pengiriman surat massal dan/atau
                      bentuk “spam” apapun atau permohonan tertentu.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="korespondensi my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Korespondensi
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Setelah Akun Bisatopup Anda aktif, Anda setuju untuk
                      menerima komunikasi dari Bisatopup melalui Nomor Handphone
                      Terdaftar, Layanan Bantuan di aplikasi Bisatopup, dan
                      Alamat E-mail terdaftar Anda mengenai layanan Bisatopup.
                      Anda setuju bahwa setiap komunikasi yang Anda terima dari
                      Bisatopup tidak akan dianggap spam (komunikasi yang tidak
                      diminta) dan/atau pelanggaran registrasi Anda.
                    </li>
                    <li>
                      Jika ada hal-hal yang ingin ditanyakan atau konfirmasi
                      data, Anda bisa mengirimkan surat kepada Bisatopup dalam
                      bentuk tertulis dan dikirimkan ke alamat Bisatopup di:
                      Attn : Bisatopup (www.bisatopup.co.id) Alamat : Ruko Griya
                      Cakra no.6, Jalan RTM Kelapa Dua Raya, Tugu, Cimanggis,
                      Depok. Atau via email ke E-mail: support@bisatopup.co.id.{" "}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="ganti-rugi my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Ganti Rugi
                </div>
                <div className="isi">
                  Dengan mendaftar dan menggunakan akun Bisatopup dan layanan
                  Bisatopup, Anda setuju untuk membebaskan PT Bisatopup
                  Teknologi Indonesia, pihak yang memberi lisensi kepada
                  Bisatopup, afiliasi Bisatopup dan setiap pegawai, direktur,
                  komisaris, karyawan, wakil dan agen Bisatopup, dari seluruh
                  tuntukan hukum termasuk klaim, biaya, kerusakan, kerugian,
                  tanggung jawab dan pengeluaran (termasuk biaya advokat) yang
                  timbul atau sehubungan dengan:
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Pelanggaran Anda atas Syarat & Ketentuan ini atau
                      ketentuan hukum dan peraturan perundang-undangan yang
                      berlaku, baik direferensikan atau tidak di sini;
                    </li>
                    <li>
                      Pelanggaran Anda atas hak pihak ketiga lainnya sehubungan
                      dengan transaksi di Bisatopup dan/atau layanan Bisatopup;
                    </li>
                    <li>
                      Setiap penggunaan atau penyalahgunaan Bisatopup dan/atau
                      layanan Bisatopup oleh Anda atau pihak lain menggunakan
                      akun Bisatopup Anda;
                    </li>
                    <li>
                      {" "}
                      Setiap kesalahan yang dilakukan oleh pihak selain
                      Bisatopup, baik disengaja maupun berupa kelalaian; atau
                    </li>
                    <li>
                      Pemblokiran akun Bisatopup Anda berdasarkan hal-hal yang
                      diatur dalam Syarat & Ketentuan ini. Ketentuan ganti rugi
                      ini akan tetap berlaku terlepas dari penutupan akun
                      Bisatopup Anda.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="hukum my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Peraturan Hukum dan Penyelesaian Sengketa
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Bisatopup mungkin memilih untuk menyelesaikan sengketa
                      atau klaim yang muncul dari atau berkaitan dengan Syarat &
                      Ketentuan ini melalui musyawarah untuk mufakat/atau
                      melakukan langkah-langkah damai melalui musyawarah mufakat
                      sejak munculnya Sengketa. Jika tidak mencapai kesepakatan
                      damai, maka sepakat menyelesaikan sengketa di forum
                      pengadilan Kota Depok yang mengikat sesuai dengan hukum
                      dan peraturan perundang-undangan yang berlaku. Setiap
                      sengketa atau klaim akan diselesaikan secara individual
                      dan tidak akan digabungkan dengan pengadilan sengketa atau
                      keluhan dari pihak lainnya.
                    </li>
                    <li>
                      Baik Anda maupun Bisatopup berhak mencari bantuan awal
                      atau sementara dari lembaga hukum yang kompeten di
                      Indonesia, yang diperlukan untuk melindungi hak atau
                      kekayaan dari Anda atau Bisatopup, selama menunggu
                      penyelesaian pengadilan. Semua proses pengadilan akan
                      dilaksanakan dalam Bahasa Indonesia.
                    </li>
                    <li>
                      Syarat & Ketentuan ini akan diatur sesuai dengan hukum dan
                      undang-undang di Indonesia. Sesuai dengan prosedur
                      pengadilan, Bisatopup dan Anda menyetujui dan menerima
                      bahwa setiap sengketa atau klaim yang muncul dari atau
                      berkaitan dengan Syarat & Ketentuan ini akan diadili
                      secara khusus hanya di bawah lembaga pengadilan yang
                      wilayah hukumnya di Indonesia.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="haki my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Hak Kekayaan Intelektual
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Anda mengakui dan menyetujui bahwa layanan dan perangkat
                      lunak apa pun yang dibutuhkan untuk terhubung dengan
                      layanan Bisatopup (“Perangkat Lunak) berisi hak milik dan
                      informasi rahasia yang dilindungi oleh kekayaan
                      intelektual dan hukum-hukum lainya yang berlaku. Anda
                      menyetujui untuk tidak memodifikasi, menyewakan,
                      meminjamkan, menjual, mendistribusikan atau menciptakan
                      pekerjaan-pekerjaan turunan dengan layanan dari Perangkat
                      Lunak Bisatopup, secara utuh ataupun secara sebagian.
                      Setiap Penggunaan komersial tanpa izin dari layanan
                      Bisatopup atau penjualan kembali dari layanan Bisatopup
                      atau penyalinan atau reproduksi dari Perangkat Lunak
                      Bisatopup ke server atau lokasi lainnya untuk reproduksi
                      dan distribusi kembali dilarang keras dan dapat
                      mengakibatkan hukuman pidana dan perdata.
                    </li>
                    <li>
                      {" "}
                      Anda mengakui bahwa Anda tidak akan (Anda juga tidak akan
                      mengizinkan pihak ketiga untuk) menyalin, memodifikasi,
                      mengumpulkan, menerjemahkan, menciptakan
                      pekerjaan-pekerjaan turunan, merekayasa balik, merekayasa
                      kembali Perangkat Lunak atau mencoba untuk menggali sumber
                      kode program, meliputi tanpa kecuali tujuan untuk
                      mendapatkan akses tanpa izin ke layanan Bisatopup.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="pengabaian my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Tidak Ada Pengabaian{" "}
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Kegagalan, Keterlambatan atau Kelalaian Bisatopup untuk
                      menerapkan atau melaksanakan hak atau ketentuan dari
                      Syarat & Ketentuan ini bukan berarti pengabaian dari hak
                      atau ketentuan tersebut. Pengabaian hak atau ketentuan apa
                      pun pada kesempatan apa pun tidak dapat diartikan sebagai
                      pengabaian hak atau ketentuan tersebut di masa depan.
                    </li>
                    <li>
                      {" "}
                      Anda menyetujui bahwa terlepas dari hukum atau
                      undang-undang yang berlaku, setiap sengketa atau klaim
                      yang muncul dari atau berkaitan dengan Penggunaan Layanan
                      Bisatopup atau Syarat & Ketentuan ini harus diajukan dalam
                      3 (tiga) bulan sejak sengketa atau klaim tersebut muncul
                      atau dianggap tidak sah/valid.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="perubahan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Perubahan Syarat & Ketentuan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Syarat & Ketentuan ini mungkin dimodifikasi setiap waktu
                      dan dari waktu ke waktu, dihentikan secara sementara atau
                      permanen (‘Direvisi’) oleh Bisatopup.
                    </li>
                    <li>
                      Anda dapat mengecek versi terbaru dari Syarat & Ketentuan
                      setiap waktu.
                    </li>
                    <li>
                      Kecuali ditetapkan oleh Bisatopup, Syarat & Ketentuan yang
                      telah direvisi akan berlaku secara otomatis dan mulai
                      mengikat sejak hari publikasinya di aplikasi Bisatopup
                      atau situs resmi Bisatopup. Dengan melanjutkan akses
                      terhadap layanan Bisatopup (mencentang tanda setuju), Anda
                      dianggap telah setuju untuk menerima dan terikat oleh
                      Syarat & Ketentuan yang Telah Direvisi tersebut. Jika Anda
                      tidak setuju terhadap Syarat & Ketentuan yang Telah
                      Direvisi tersebut, Anda harus segera berhenti mengakses
                      atau menggunakan layanan Bisatopup.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="perjanjian-akad">
            <div className="judul text-fbase lg:text-fxl font-bold  text-center my-4">
              *Perjanjian (Akad) Kesepahaman Mitra*
            </div>
            <div className="isi text-fsm lg:text-fmd text-justify">
              <div>
                Syarat & Ketentuan yang ditetapkan di bawah ini mengatur terkait
                penggunaan aplikasi yang ditawarkan oleh PT. Bisatopup Teknologi
                Indonesia yaitu aplikasi Bisatopup. Pengguna disarankan membaca
                dengan seksama karena dapat berdampak kepada hak dan kewajiban
                Pengguna di bawah hukum.
                <br /> <br /> Dengan mendaftar dan/atau menggunakan aplikasi
                Bisatopup, maka pengguna dianggap telah membaca, mengerti,
                memahami dan menyetujui semua isi dalam Syarat & Ketentuan.
                Syarat & Ketentuan ini merupakan bentuk kesepakatan yang
                dituangkan dalam sebuah perjanjian yang sah antara Pengguna
                dengan PT. Bisatopup Teknologi Indonesia. Jika Pengguna tidak
                menyetujui salah satu, sebagian, atau seluruh isi Syarat &
                Ketentuan, maka Pengguna tidak diperkenankan menggunakan layanan
                aplikasi Bisatopup.
              </div>

              <div className="lingkup-perjanjian my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Lingkup Perjanjian
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>Deposit Pihak Kedua</li>
                    <li>Jual/Beli Akad Jasa Layanan (Prepaid)</li>
                    <li>Jual/Beli Akad Jasa Pembayaran Tagihan (Postpaid)</li>
                  </ol>
                </div>
              </div>
              <div className="ketentuan-perjanjian my-8">
                <div className="ketentuan-perjanjian font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Ketentuan Perjanjian
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Penyerahan uang dari Pihak Kedua kepada Pihak Pertama
                      melalui rekening ditentukan oleh Pihak Pertama adalah Akad
                      Wadiah/Titipan yang disimpan pada:{" "}
                      <ol className="list-none space-y-2">
                        <li>
                          {" "}
                          a. Rekening Bank atas nama Pihak Pertama (PT Amanah
                          Karya Indonesia atau PT Bisatopup Teknologi Indonesia)
                        </li>
                        <li>b. Dan/atau Rekening Supplier </li>
                      </ol>{" "}
                    </li>
                    <li>
                      Uang deposit adalah uang halal baik sumber dan cara
                      perolehannya, Pihak Pertama terbebas dari tuntutan yang di
                      kemudian hari ada pada Pihak Kedua atas setoran deposit
                      ini.{" "}
                    </li>
                    <li>
                      Pihak Pertama hanya berhak menggunakan dana tersebut yang
                      keperluannya terbatas pada kepentingan Pihak Kedua,
                      demikian Pihak Pertama tidak bertanggung jawab pada risiko
                      deposit yang berada di tangan Supplier, maupun di rekening
                      Bank.
                    </li>
                    <li>
                      Pihak Pertama wajib bertanggung jawab/mengembalikan
                      apabila melakukan kelalaian dalam mengelola rekening
                      deposit dan/atau menyalahgunakan untuk kepentingan di luar
                      kepentingan Pihak Kedua.{" "}
                    </li>
                    <li>Pihak Pertama berhak meminta fee/biaya pengelolaan.</li>
                    <li>
                      Pihak Kedua berhak menggunakan deposit tersebut untuk
                      pembelian layanan dan/atau ditarik kembali dalam bentuk
                      uang.
                    </li>
                    <li>
                      Penggunaan deposit diakui sebagai pengembalian titipan.
                    </li>
                    <li>
                      Apabila deposit digunakan untuk pembayaran dan atau
                      pembelian Produk maka sejak saat itu deposit berpindah
                      kepemilikan menjadi milik penjual, dan Pihak Pertama dalam
                      hal jasa membayarkan berhak mendapaykan fee (ujrah).{" "}
                    </li>
                    <li>
                      Setoran awal adalah titipan pertama, setiap penambahan
                      adalah wadiah/titipan yang baru yang ketentuannya mengacu
                      pada Perjanjian ini.
                    </li>
                    <li>
                      Akad Pembelian Layanan yang terjadi dikemudian hari tetap
                      mengacu pada Perjanjian ini.
                    </li>
                    <li>
                      {" "}
                      Dalam hal pembelian layanan maka Pihak Kedua tunduk pada
                      ketentuan dan tata cara yang diberikan oleh Pihak Pertama
                      dan hal ini tetap dianggap sebagai pengembalian pinjaman.{" "}
                    </li>
                    <li>
                      {" "}
                      Apabila Pihak Pertama dinyatakan bangkrut oleh pihak
                      berwenang maka rekening deposit tetap menjadi milik Pihak
                      Kedua yang pengaturannya diatur secara adil dan diserahkan
                      kepada salah satu pihak untuk mewakili Pihak Kedua.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="akad-layanan my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Akad Jual Beli Jasa Layanan
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Pihak Pertama adalah PPOB yang seperti diketahui sebagai
                      penyedia Jasa Layanan Prepaid, Jasa Wakalah dan Jasa
                      Ijarah.{" "}
                    </li>
                    <li>
                      Pihak Kedua adalah agen atau member yang berniat membeli
                      layanan yang disediakan Pihak Pertama.{" "}
                    </li>
                    <li>
                      Pihak Pertama memberikan layanan (software aplikasi)
                      kepada Pihak Kedua untuk melakukan transaksi:{" "}
                      <ol className="list-none space-y-2">
                        <li>a. Deposit </li>
                        <li>b. Pembelian Jasa </li>
                      </ol>{" "}
                    </li>
                    <li>
                      Apabila Pihak Kedua melakukan Pembelian Jasa melalui
                      sarana aplikasi maka berlaku akad Jual Beli Jasa (Ijarah),
                      atau Jasa Wakalah Membelikan dan/atau jasa Membayarkan.
                    </li>
                    <li>
                      {" "}
                      Jasa Layanan yang tersedia adalah sebagaimana ditampilkan
                      dalam aplikasi yang dibuka oleh Pihak Kedua.
                    </li>
                    <li>
                      {" "}
                      Jasa Layanan yang dibeli oleh Pihak Kedua adalah Jasa yang
                      sudah dimiliki Pihak Pertama.{" "}
                    </li>
                    <li>
                      Jasa Membelikan dan/atau Membayarkan adalah Jasa yang
                      masih menjadi milik Supplier, dibayar dengan deposit Pihak
                      Kedua.{" "}
                    </li>
                    <li>
                      Setelah Jasa Layanan diterima Pihak Kedua maka Pihak
                      Pertama terbebas dari tanggung jawab pengiriman/pemberian
                      Jasa Layanan.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="akad-pembayaran my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Akad Jual Beli Jasa Pembayaran
                </div>
                <div className="isi">
                  <ol className="list-decimal list-outside space-y-2 mt-4">
                    <li>
                      Tagihan/billing adalah hutang pihak Konsumen kepada
                      penyedia.
                    </li>
                    <li>
                      Tagihan hanya berlaku dalam hal produk halal tidak boleh
                      boleh (haram) seperti dan tidak terbatas pada bunga
                      (riba), dan transaksi produk haram/maksiat.
                    </li>
                    <li>
                      Tidak ada transaksi yang boleh mendapat keuntungan pada
                      jasa talangan, karena hal ini termasuk Hawalah dengan Riba
                      (pertambahan).
                    </li>
                    <li>
                      Transaksi Jasa adalah membayarkan tagihan, dimana uang
                      berasal dari pemilik tagihan.
                    </li>
                    <li>Pihak Pertama menerima amanah membayarkan.</li>
                    <li>
                      Pihak Pertama menerima fee/ujrah karena memiliki kemampuan
                      membayar lewat sarana aplikasi.
                    </li>
                    <li>
                      Ketika Pihak Kedua membeli jasa lewat aplikasi/sistem
                      Pihak Pertama, Pihak Kedua harus sudah menerima
                      pembayaran.
                    </li>
                    <li>
                      Ketika Pihak Kedua membeli jasa maka deposit dipotong
                      terlebih dahulu untuk membayar sebesar fee dan besaran
                      tagihan.
                    </li>
                    <li>
                      Apabila tagihan sudah terbayar maka tugas Pihak Pertama
                      dan Pihak Kedua selesai, selanjutnya konsumen sebagai
                      pemilik tagihan berhubungan langsung dengan penyedia
                      tagihan (layanan).{" "}
                    </li>
                    <li>
                      Pihak Pertama tidak bertanggung jawab apabila tagihan
                      sudah terbayar namun status belum lunas kecuali dalam hal
                      Pihak Pertama melakukan kelalaian.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="penutup">
            <div className="judul text-fbase lg:text-fxl font-bold  text-center my-4">
              *Penutup*
            </div>
            <div className="isi text-fsm lg:text-fmd text-justify">
              <ol className="list-decimal list-outside space-y-2 mt-4">
                <li>
                  Dalam pelaksanaan Perjanjian ini apabila terjadi permasalahan
                  maka Para Pihak sepakat menyelesaikannya dengan cara
                  musyawarah untuk mufakat dengan tunduk pada ketentuan Syariah,
                  apabila tidak terjadi mufakat maka Para Pihak sepakat menunjuk
                  pihak ketiga yang berkompeten sebagai pemutus atau
                  menyelesaikan di pengadilan hukum Syariah di Kota Depok.
                </li>
                <li>
                  Apabila ada hal-hal yang belum atau belum cukup diatur dalam
                  Perjanjian ini, Para Pihak akan mengaturnya Bersama secara
                  musyawarah untuk mufakat dalam suatu addendum atau dokumen
                  tertulis lainnya dan merupakan bagian yang tidak terpisahkan.
                </li>
                <li>
                  {" "}
                  Perjanjian dan/atau perubahannya berlaku selama tidak
                  mengandung unsur yang melanggar hukum syar’i/syariat Islam
                  misalnya terbebas dari unsur-unsur yang mengandung Ghohor dan
                  Riba.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Faq;
