import React from "react";
import Navbar from "../../components/Navbar/Navbar";

import jumbotronImg from "../../images/content8.png";

const DaftarProduk = () => {
  return (
    <>
      <Navbar active="daftarProduk" />
      <header className="py-4 md:py-0">
        <section className="w-11/12 lg:w-10/12 mx-auto">
          <div className="md:grid grid-cols-12">
            <div className="md:hidden">
              <img src={jumbotronImg} alt="" />
            </div>
          </div>
        </section>

        <div className="hidden md:grid items-center grid-cols-12 w-9/12 lg:w-9/12 mx-auto gap-4">
          <div className="col-span-5 flex flex-col gap-3 lg:gap-4">
            <h1 className="text-flg md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
              Daftar Produk <br />
              Bisatopup
            </h1>

            <p className="text-fsm lg:text-fmd xl:text-lg font-light text-gray-400">
              Menyediakan berbagai produk original <br />
              Bisatopup Official Store berupa produk <br />
              Aqiqah, Qurban, dan Merchandise
            </p>

            <div className="flex gap-3 lg:gap-4 w-10/12 xl:w-9/12">
              <button className="bg-primary shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-2/3 rounded-xl xl:rounded-2xl py-3 xl:py-4 my-1">
                <div className="flex items-center justify-center gap-2 md:gap-3 xl:gap-4 text-white text-fsm lg:text-fmd font-bold">
                  <h5>Lihat Produk</h5>
                  <i className="fas fa-arrow-down"></i>
                </div>
              </button>
            </div>
          </div>

          <div className="col-span-7 p-0">
            <img src={jumbotronImg} className="w-full p-0" alt="" />
          </div>
        </div>
      </header>
    </>
  );
};

export default DaftarProduk;
