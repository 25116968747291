import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";

import manfaapsImg from "../../images/content2.png";

const Manfaaps = () => {
  return (
    <>
      <section className="my-12 lg:my-14 xl:my-16 overflow-hidden">
        <div
          className="w-11/12 mx-auto rounded-xl lg:rounded-3xl"
          style={{ backgroundColor: " #f4fcfc" }}
        >
          <div className="w-11/12 mx-auto py-9">
            {/* <!-- Mobile --> */}
            <div className="md:hidden w-full">
              <div>
                <h5 className="font-bold text-primary text-fxs mb-3">
                  FITUR KOMPLIT, TRANSAKSI CEPAT
                </h5>
                <h1 className="text-flg font-bold leading-tight mb-12">
                  Aplikasi Bermanfaat <br />
                  Serba Bisa.
                </h1>
              </div>
              <div className="flex flex-col md:flex-row gap-4 relative md:static">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <div className="bg-white p-1 w-10 text-center rounded-xl">
                      <FontAwesomeIcon
                        icon={faUserFriends}
                        className="text-fmd"
                      />
                    </div>
                    <div>
                      <h5 className="text-fsm font-semibold mb-1">
                        Tidak Perlu Antre
                      </h5>
                      <p className="text-fxs text-gray-500 w-5/12">
                        Anda tidak perlu antre dan keluar rumah, cukup membuka
                        website atau aplikasi untuk bertransaksi.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="bg-white p-1 w-10 text-center rounded-xl">
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="text-fmd"
                      />{" "}
                    </div>
                    <div>
                      <h5 className="text-fsm font-semibold mb-1">
                        Deposit/Non Deposit
                      </h5>
                      <p className="text-fxs text-gray-500 w-5/12">
                        Anda dapat melakukan transaksi dengan deposit ataupun
                        tanpa deposit.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="bg-white p-1 w-10 text-center rounded-xl">
                      <FontAwesomeIcon icon={faTag} className="text-fmd" />{" "}
                    </div>
                    <div>
                      <h5 className="text-fsm font-semibold mb-1">
                        Spesialis Harga Murah
                      </h5>
                      <p className="text-fxs text-gray-500 w-5/12">
                        Kami memberikan jaminan harga termurah untuk pengguna
                        Bisatopup.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="absolute left-36 w-72">
                  <img src={manfaapsImg} alt="" />
                </div>
              </div>
            </div>
            {/*  <!-- End Mobile --> */}
            {/*  <!-- Desktop --> */}
            <div className="hidden md:flex">
              <div className="w-6/12 mt-5">
                <img src={manfaapsImg} alt="" />
              </div>
              <div className="xl:w-1/12"></div>
              <div className="w-7/12 xl:w-6/12">
                <h5 className="font-bold text-primary text-fxs md:text-fsm xl:text-fmd mb-3">
                  FITUR KOMPLIT, TRANSAKSI CEPAT
                </h5>
                <h1 className="text-flg md:text-fxl xl:text-f3xl font-bold leading-tight mb-8">
                  Aplikasi Bermanfaat <br />
                  Serba Bisa.
                </h1>

                <div className="flex flex-col gap-2 lg:gap-4">
                  <div className="flex gap-5 xl:gap-7 mb-4 xl:mb-6">
                    <div className="bg-white p-2 xl:p-4 h-9 xl:h-14 text-center rounded-xl">
                      <FontAwesomeIcon
                        icon={faUserFriends}
                        className="text-fmd lg:text-fbase xl:text-flg"
                      />
                    </div>
                    <div>
                      <h5 className="text-fmd xl:text-fbase font-semibold mb-1 xl:mb-2">
                        Tidak Perlu Antre
                      </h5>
                      <p className="text-fsm md:text-sm lg:text-fmd xl:text-fmd text-gray-500 w-9/12">
                        Anda tidak perlu antre dan keluar rumah,
                        <span className="xl:block"></span>
                        cukup membuka website atau aplikasi untuk bertransaksi.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-5 xl:gap-7 mb-4 xl:mb-6">
                    <div className="bg-white p-2 xl:p-4 h-9 xl:h-14 text-center rounded-xl">
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="text-fmd lg:text-fbase xl:text-flg"
                      />{" "}
                    </div>
                    <div>
                      <h5 className="text-fmd xl:text-fbase font-semibold mb-1 xl:mb-2">
                        Deposit/Non Deposit
                      </h5>
                      <p className="text-fsm md:text-sm lg:text-fmd xl:text-fmd text-gray-500 w-full">
                        Anda dapat melakukan transaksi dengan <br />
                        deposit ataupun tanpa deposit.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-5 xl:gap-7 mb-4 xl:mb-6">
                    <div className="bg-white p-2 xl:p-4 h-9 xl:h-14 text-center rounded-xl">
                      <FontAwesomeIcon
                        icon={faTag}
                        className="text-fmd lg:text-fbase xl:text-flg"
                      />{" "}
                    </div>
                    <div>
                      <h5 className="text-fmd xl:text-fbase font-semibold mb-1 xl:mb-2">
                        Spesialis Harga Murah
                      </h5>
                      <p className="text-fsm md:text-sm lg:text-fmd xl:text-fmd text-gray-500 w-full">
                        Kami memberikan jaminan harga <br />
                        termurah untuk pengguna Bisatopup.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*           <!-- End Desktop -->
             */}{" "}
          </div>
        </div>
      </section>
    </>
  );
};

export default Manfaaps;
