import React from "react";
import gambar from "../../images/content4.png";

const User = () => {
  return (
    <>
      <section className="my-8 lg:my-14 xl:my-16">
        <div
          className="w-11/12 mx-auto py-6 xl:py-10 rounded-xl lg:rounded-2xl"
          style={{ backgroundColor: "#fffbf4" }}
        >
          <div className="text-center">
            <h6 className="font-bold text-fxs md:text-fsm xl:text-fmd text-orange-300 mb-2">
              Telah Mencapai Ratuan Ribu Pengguna Se-Indonesia
            </h6>
            <h1 className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
              Lebih dari 8 Juta Transaksi <br /> Terselesaikan Secara Mudah
              Dengan Bisatopup
            </h1>
          </div>

          <div className="my-2 md:my-6 lg:my-9 xl:my-14 md:w-11/12 mx-auto">
            <img src={gambar} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default User;
