import React from "react";
import { useField } from "formik";

const CustomInput = ({ classInput, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <input
        {...field}
        {...props}
        className={`${
          meta.touched && meta.error ? "input-error" : ""
        } ${classInput}`}
      />
      {meta.touched && meta.error && (
        <div className="text-red-600 text-fxs md:text-fmd">{meta.error}</div>
      )}
    </>
  );
};

export default CustomInput;
