import React, { useState } from "react";

import metodeImg from "../../images/content3.png";
import bank from "../../images/bank.png";
import saldo from "../../images/saldo.png";
import ewallet from "../../images/ewallet.png";
import gerai from "../../images/gerai.png";

const MetodePembayaran = () => {
  const [currentLayananTab, setCurrentLayananTab] = useState("1");

  const tabs = [
    {
      id: 1,
      title: "Saldo Bisatopup",
      content: saldo,
      class: "mt-10 w-1/2",
    },
    {
      id: 2,
      title: "Transfer Bank",
      content: bank,
    },
    {
      id: 3,
      title: "Virtual Account",
      content: bank,
    },
    {
      id: 4,
      title: "E-Wallet",
      content: ewallet,
    },
    {
      id: 5,
      title: "Gerai",
      content: gerai,
    },
  ];

  const handleTabClick = (e) => {
    setCurrentLayananTab(e.target.id);
  };

  return (
    <>
      <section className="mt-12 lg:mt-14 xl:mt-16">
        <div className="w-10/12 mx-auto">
          <div className="md:flex items-center">
            <div className="md:w-8/12 lg:w-7/12">
              <h6 className="font-bold text-fxs md:text-fmd text-primary mb-2">
                METODE PEMBAYARAN
              </h6>
              <h1 className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Tersedia Berbagai Macam <br />
                Metode Pembayaran
              </h1>

              <div className="grid grid-cols-12 mt-5">
                <div className="col-span-7 md:col-span-12">
                  <p className="text-fxs md:text-fsm lg:text-fmd xl:text-fbase text-gray-400">
                    Solusi kami memudahkan Anda untuk
                    <span className="block md:inline"></span> menerima
                    pembayaran
                    <span className="inline md:block"></span> online dan
                    offline.
                    <span className="block md:inline"></span>
                    Dengan berbagai variasi pembayaran online
                    <span className="block"></span> dan offline, pengguna dapat
                    memilih untuk
                    <span className="block md:inline"></span> menggunakan
                    <span className="inline md:block"></span> pembayaran yang
                    disukai.
                  </p>
                </div>

                <div className="col-span-5 -mt-5 md:hidden">
                  <img src={metodeImg} className="w-full" alt="" />
                </div>
              </div>
            </div>
            <div className="md:w-4/12 lg:w-5/12 hidden md:block">
              <img src={metodeImg} alt="" />
            </div>
          </div>
        </div>

        <div className="mb-12 lg:mb-14 xl:mb-16">
          <div className="w-11/12 md:w-10/12 lg:w-9/12 mx-auto border-b border-t border-primary">
            <ul className="flex gap-4 md:gap-16 xl:gap-20 text-fxs md:text-fsm lg:text-fmd xl:text-lg text-gray-400 font-medium items-center justify-center w-11/12 mx-auto">
              {tabs.map((tab, tabIdx) => {
                return (
                  <button
                    disabled={currentLayananTab === `${tab.id}`}
                    onClick={handleTabClick}
                    id={tab.id}
                    key={tabIdx}
                    className={`${
                      currentLayananTab === `${tab.id}`
                        ? "text-primary border-b-2"
                        : ""
                    } py-3 hover:border-b-2 hover:text-primary border-orange-400`}
                  >
                    {tab.title}
                  </button>
                );
              })}
            </ul>
          </div>

          <div className="w-9/12 lg:w-8/12 mx-auto my-3 md:my-5">
            {tabs.map((tab, tabIdx) => {
              return (
                <img
                  className={`mx-auto  my-4 ${tab.class}`}
                  key={tabIdx}
                  id={tab.tabTitle}
                  src={currentLayananTab === `${tab.id}` ? tab.content : ""}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default MetodePembayaran;
