import React from "react";

const Tonton = () => {
  return (
    <>
      <section class="container w-10/12 mx-auto my-14">
        <h1 class="text-xl text-center font-bold lg:text-2xl xl:text-3xl">
          Tonton dulu aja, <br />
          Tak kenal tanda tak sayang 🤝
        </h1>
        <div class="mt-10 mb-6">
          <iframe
            class="mx-auto rounded-xl md:w-[720px] md:h-[360px]"
            src="https://www.youtube.com/embed/UYm5vfdvnCY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="flex flex-col md:flex-row  justify-center gap-x-8">
          <div class="video mb-6 md:mb-0">
            <h1 class="text-xl text-center font-bold lg:text-2xl mb-6 mb-2">
              Podcast Kasisolusi
            </h1>
            <iframe
              class="mx-auto rounded-xl md:w-[480px] md:h-[240px]"
              src="https://www.youtube.com/embed/3e-R7UYq63o"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="video mb-6 md:mb-0">
            <h1 class="text-xl text-center font-bold lg:text-2xl mb-6 mb-2">
              Sisi lain Bisatopup
            </h1>
            <iframe
              class="mx-auto rounded-xl md:w-[480px] md:h-[240px]"
              src="https://www.youtube.com/embed/QhNBOHFR2dc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tonton;
