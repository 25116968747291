import React, { useState } from "react";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const Navbar = ({ active }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <section className="md:py-8 top-0 sticky md:static z-30 bg-white">
        <div className="flex flex-col mx-auto md:items-center w-10/12 md:justify-between md:flex-row">
          {/* <!--Nav Mobile Toggle --> */}
          <div className="flex flex-row items-center justify-between py-5 md:py-0">
            <Link to="/">
              <img src={logo} className="h-6 md:h-7 lg:h-9" alt="logo" />
            </Link>

            <button
              className="md:hidden p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
          {/*  <!--Nav Mobile End --> */}

          <nav
            //   :className="{'flex': open, 'hidden': !open}"
            className={`flex-col md:h-0 bg-white rounded-b-2xl flex-grow md:pb-0 ${
              isOpen ? "block" : "hidden"
            } md:flex md:justify-end md:flex-row`}
          >
            {/* <!-- Navbar Mobile --> */}
            <div className="block md:hidden">
              <div className="flex justify-between items-center py-3 bg-white mt-2">
                <Link
                  className={` ${
                    active === "home" ? "text-primary" : "text-black"
                  }  font-medium hover:text-primary text-fmd`}
                  to="/"
                >
                  Home
                </Link>
              </div>
              <div className="flex justify-between items-center py-3 bg-white">
                <Link
                  className={` ${
                    active === "services" ? "text-primary" : "text-black"
                  }  font-medium hover:text-primary text-fmd`}
                  to="/services"
                >
                  Services
                </Link>
              </div>
              <div className="flex justify-between items-center py-3 bg-white">
                <Link
                  className={` ${
                    active === "about" ? "text-primary" : "text-black"
                  }  font-medium hover:text-primary text-fmd`}
                  to="/about"
                >
                  Tentang
                </Link>
              </div>
              <div className="flex justify-between items-center py-3 bg-white">
                <Link
                  className={` ${
                    active === "terms-condition" ? "text-primary" : "text-black"
                  }  font-medium hover:text-primary text-fmd`}
                  to="/terms-condition"
                >
                  Terms & Condition
                </Link>
              </div>
              <div className="flex justify-between items-center py-3 bg-white">
                <Link
                  className={` ${
                    active === "kebijakan-privasi"
                      ? "text-primary"
                      : "text-black"
                  }  font-medium hover:text-primary text-fmd`}
                  to="/kebijakan-privasi"
                >
                  Kebijakan Privasi
                </Link>
              </div>
              <div className="flex justify-between items-center py-3 bg-white">
                <a
                  className={`font-medium hover:text-primary text-fmd`}
                  href="https://documenter.getpostman.com/view/367648/RzfZQZAL?version=latest"
                  target="_blank"
                >
                  API
                </a>
              </div>
              {/* <div className="flex justify-between items-center py-3 bg-white">
                <a className="text-fmd font-medium" href="#">
                  Daftar Gratis
                </a>
              </div> */}
            </div>
            {/*           <!-- End Navbar Mobile -->
             */}

            {/* Web View */}
            <div className="hidden md:flex">
              <ul className="flex items-center font-medium gap-8 lg:gap-12">
                <li>
                  <Link
                    className={` ${
                      active === "home" ? "text-primary" : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      active === "services" ? "text-primary" : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    to="/services"
                  >
                    Services
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={` ${
                      active === "daftarProduk" ? "text-primary" : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    to="/daftar-produk"
                  >
                    Daftar Produk
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={` ${
                      active === "about" ? "text-primary" : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    to="/about"
                  >
                    Tentang
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      active === "terms-condition"
                        ? "text-primary"
                        : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    to="/terms-condition"
                  >
                    Terms Condition
                  </Link>
                </li>
                <li>
                  <Link
                    className={` ${
                      active === "kebijakan-privasi"
                        ? "text-primary"
                        : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    to="/kebijakan-privasi"
                  >
                    Kebijakan Privasi
                  </Link>
                </li>
                <li>
                  <a
                    className={` ${
                      active === "API" ? "text-primary" : "text-black"
                    }  font-bold hover:text-primary text-fsm lg:text-fmd`}
                    href="https://documenter.getpostman.com/view/367648/RzfZQZAL?version=latest"
                    target="_blank"
                  >
                    API
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Navbar;
