import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LandingPage from "./pages/LandingPage/LandingPage";
import Services from "./pages/Services/Services";
import DaftarProduk from "./pages/DaftarProduk/DaftarProduk";
import About from "./pages/About/About";
import Faq from "./pages/FAQ/Faq";
import Privacy from "./pages/FAQ/KebijakanPrivasi";
import Affiliate from "./pages/Affiliate/Affiliate";

function App() {
  return (
    <>
      {" "}
      <BrowserRouter>
        <div className="">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/daftar-produk" element={<DaftarProduk />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms-condition" element={<Faq />} />
            <Route path="/syarat-&-ketentuan" element={<Faq />} />
            <Route path="/kebijakan-privasi" element={<Privacy />} />
            <Route path="/mobile/privacy" element={<Privacy />} />
            <Route path="/affn/:affiliate" element={<Affiliate />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
