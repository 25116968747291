import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import jumbotronImg from "../../images/content1.png";

const Jumbotron = () => {
  return (
    <>
      <section className="p-0">
        <section className="md:w-11/12 lg:w-10/12 mx-auto">
          <div className="md:grid grid-cols-12">
            <div className="md:hidden">
              <img src="/src/img/content1.png" alt="" />
            </div>
          </div>
        </section>

        {/*  <!-- Web View --> */}
        <div className="hidden md:grid items-center grid-cols-12 gap-4">
          <div className="col-span-1"></div>
          <div className="col-span-5 flex flex-col gap-3 lg:gap-5">
            <h1 className="text-flg lg:text-fxl xl:text-f2xl font-bold leading-tight">
              Satu Aplikasi Banyak <br />
              Manfaat, Banyak <br />
              Untungnya
            </h1>

            <p className="text-fsm lg:text-fmd xl:text-fbase font-light text-gray-400">
              Aplikasi yang memudahkan kamu untuk pembayaran <br />
              dimanapun dan kapanpun!
            </p>

            <div className="flex gap-3 lg:gap-4 w-10/12 xl:w-9/12">
              <button className="bg-primary shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-2/3 rounded-xl xl:rounded-2xl py-3 my-1">
                <a
                  href="https://berbagi.link/bisatopup/download"
                  target="_blank"
                  className="flex items-center justify-center gap-2 md:gap-3 xl:gap-4 text-white text-fsm lg:text-fmd xl:text-fbase font-bold"
                >
                  <h5>Download</h5>
                  <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </button>

              {/* <button className="bg-white hover:bg-primary border-2 hover:border-transparent border-gray-200 text-primary hover:text-white w-2/3 rounded-2xl py-3 my-1">
                <div className="justify-center gap-2 text-fsm lg:text-fmd xl:text-fbase font-bold">
                  <h5>Cek Harga</h5>
                </div>
              </button> */}
            </div>
          </div>

          <div className="col-span-6 p-0">
            <img src={jumbotronImg} className="w-full p-0" alt="" />
          </div>
        </div>

        {/* <!-- Mobile view --> */}
        <div className="md:hidden w-10/12 mx-auto">
          <div className="w-11/12 flex flex-col gap-2 py-4">
            <h1 className="text-flg font-bold leading-tight">
              Satu Aplikasi Banyak <br />
              Manfaat, Banyak <br />
              Untungnya
            </h1>

            <p className="text-fsm text-gray-400">
              Aplikasi yang memudahkan kamu untuk <br />
              pembayaran dimanapun dan kapanpun!
            </p>

            <button className="bg-primary hover:bg-teal-400 w-2/3 rounded-2xl py-3 my-1">
              <Link
                to="https://berbagi.link/bisatopup/download"
                target="_blank"
                className="flex items-center justify-center gap-2 text-white text-base font-bold"
              >
                <h5>Download</h5>
                <FontAwesomeIcon icon={faArrowRight} />{" "}
              </Link>
            </button>

            {/* <button className="bg-white hover:bg-primary border-2 border-gray-200 hover:border-0 w-2/3 rounded-2xl py-2 my-1">
              <div className="flex items-center justify-center gap-2 text-primary hover:text-white text-base font-bold">
                <h5>Cek Harga</h5>
              </div>
            </button> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Jumbotron;
