/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import logo from "../../images/logo.png";
import bsi from "../../images/bsi_.png";
import pse from "../../images/pse.png";
import eta_ from "../../images/eta_.png";
import ssl from "../../images/ssl.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="h-80 border-t-2 border-gray-200 mt-24">
        <section className="w-10/12 mx-auto">
          <div className="grid grid-cols-12 gap-7 py-8">
            <div className="col-span-12 md:col-span-5 flex flex-col gap-1 md:gap-2">
              <img src={logo} className="w-2/4 lg:w-3/6" alt="logo" />
              <h5 className="font-bold text-fmd md:text-fsm lg:text-base xl:text-xl">
                PT BISATOPUP TEKNOLOGI INDONESIA
              </h5>

              <p className="text-fsm md:text-fsm lg:text-md xl:text-fmd leading-5">
                Ruko Griya Cakra No.6 JL RTM Kelapa Dua, <br />
                Cimanggis, Kota Depok 16451 <br />
                021-22824412
              </p>

              <div className="text-fbase flex items-center gap-4">
                <a href="https://www.facebook.com/bisatopup" target="_blank">
                  <FontAwesomeIcon
                    icon={faFacebookSquare}
                    className="hover:text-blue-600"
                  />
                </a>
                <a
                  href="https://www.instagram.com/bisatopup/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="hover:text-blue-600"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCi9n2kczR8rFkyA88bdXDdQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="hover:text-blue-600"
                  />
                </a>
                <a
                  href="https://t.me/bisatopupcs_bot"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTelegram}
                    className="hover:text-blue-600"
                  />
                </a>
              </div>
            </div>

            <div className="col-span-3 md:col-span-2">
              <h5 className="text-fmd lg:text-fbase xl:text-xl md:font-semibold font-bold mb-6">
                Links
              </h5>
              <ul className="flex flex-col gap-3">
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <Link to="/">Home</Link>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <Link to="/services">Services</Link>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <Link to="/daftar-produk">Daftar Produk</Link>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <Link to="/terms-condition">Terms Condition</Link>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <Link to="/kebijakan-privasi">Kebijakan Privasi</Link>
                </li>
              </ul>
            </div>

            <div className="col-span-4 md:col-span-2">
              <h5 className="text-fmd lg:text-fbase xl:text-xl md:font-semibold font-bold mb-6">
                Layanan
              </h5>
              <ul className="flex flex-col gap-3">
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <a
                    href="https://berbagi.link/bisatopup/download"
                    target="_blank"
                  >
                    Solusi Pembayaran
                  </a>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <a href="https://bisabiller.com/whitelabel" target="_blank">
                    Host to Host
                  </a>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <a href="https://bisabiller.com/whitelabel" target="_blank">
                    Whitelabel
                  </a>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <a href="mailto:sales@bisatopup.co.id" target="_blank">
                    Kemitraan
                  </a>
                </li>
                <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                  <Link to="/" target="_blank">
                    Tentang Kami
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-span-5 md:col-span-3">
              <h5 className="text-fmd lg:text-fbase xl:text-xl md:font-semibold font-bold mb-6">
                Kontak
              </h5>
              <div className="flex flex-col gap-3">
                <div>
                  <ul className="flex flex-col gap-3">
                    <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                      <a
                        href="https://api.whatsapp.com/send/?phone=62816266755&text&type=phone_number&app_absent=0"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex items-center gap-2">
                          <FontAwesomeIcon icon={faWhatsapp} />

                          <p>0816266755</p>
                        </div>
                      </a>
                    </li>
                    <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                      <a
                        href="mailto:support@bisatopup.co.id"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex items-center gap-2 hover:cursor-pointer">
                          <FontAwesomeIcon icon={faEnvelope} />
                          <p>support@bisatopup.co.id</p>
                        </div>
                      </a>
                    </li>
                    <li className="text-fsm lg:text-fmd xl:text-fbase hover:text-gray-500">
                      <a href="https://t.me/bisatopupcs_bot" target="_blank">
                        <div className="flex items-center gap-2">
                          <FontAwesomeIcon icon={faTelegram} />
                          <p>BisaTopup Support</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="certificate grid grid-cols-12 ">
            <a
              href="https://berbagi.link/Bisatopup/LisensiPSEKominfo"
              target="_blank"
              className="col-span-3 mx-auto md:w-3/4"
              rel="noreferrer"
            >
              <img src={pse} alt="" />
            </a>
            <a
              href="https://berbagi.link/Bisatopup/LisensiETA"
              target="_blank"
              className="col-span-3 mx-auto md:w-3/4"
              rel="noreferrer"
            >
              <img src={eta_} alt="" />
            </a>
            <a
              href="https://berbagi.link/Bisatopup/SertifikasiISO27001"
              target="_blank"
              className="col-span-3 mx-auto md:w-2/4"
              rel="noreferrer"
            >
              <img src={bsi} alt="" />
            </a>
            <a href="#" className="col-span-3 mx-auto md:w-2/4" rel="noreferrer">
              <img src={ssl} alt="" />
            </a>
          </div>
        </section>

        <small className="text-fxs md:text-fsm lg:text-fmd">
          <p className="text-center py-4 md:py-7">
            © PT BISATOPUP TEKNOLOGI INDONESIA
          </p>
        </small>
      </footer>
    </>
  );
};

export default Footer;
