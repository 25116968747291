import React from "react";

import icon7 from "../../images/icon7.png";
import icon8 from "../../images/icon8.png";
import icon9 from "../../images/icon9.png";
import icon10 from "../../images/icon10.png";
import icon11 from "../../images/icon11.png";

const Join = () => {
  const contents = [
    {
      id: 1,
      icon: icon7,
      title: "Terpercaya",
      desc: "Sejak 2014 melayani jutaan pelanggan seluruh nusantara, beragam transaksi dapat diselesaikan dalam beberapa klik.",
    },
    {
      id: 2,
      icon: icon8,
      title: "Mudah dan Cepat",
      desc: "Bisatopup selalu meningkatkan pelayanan, agar pelanggan bisa bertransaksi dengan mudah dan cepat dimana saja dan kapan saja.",
    },
    {
      id: 3,
      icon: icon9,
      title: "Harga Terjangkau",
      desc: "Bisatopup memberikan harga produk jaminan terjangkau, lebih murah dibandingkan PPOB yang lain.",
    },
    {
      id: 4,
      icon: icon10,
      title: "Bantuan 24 Jam",
      desc: "Customer Service kami siap membantu Anda 24 jam nonstop, melalui fitur bantuan pada aplikasi.",
    },
    {
      id: 5,
      icon: icon11,
      title: "Fitur Super Lengkap",
      desc: "Bekerja sama dengan ratusan biller terpercaya, kami menyediakan pulsa, kuota data, token PLN, tiket kereta api hingga tiket pesawat dengan cepat.",
    },
  ];

  return (
    <>
      <section className="my-8 lg:my-14 xl:my-16">
        <div className="text-center">
          <h2 className="text-fbase md:text-flg lg:text-fxl xl:text-f2xl font-bold mb-0 lg:mb-2 leading-tight">
            Gabung di Bisatopup <br />
            lebih menguntungkan!
          </h2>
          <p className="border-b-4 border-orange-400 inline text-white">
            ----------
          </p>
        </div>

        <div className="w-8/12 md:w-10/12 lg:w-10/12 mx-auto my-7">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-9 lg:gap-16 xl:gap-24 my-4">
            {contents.map((content, contentIdx) => (
              <div key={contentIdx} className="text-center md:text-left">
                <div className="flex flex-col md:flex-row gap-2 md:gap-3 lg:gap-5">
                  <img
                    src={content.icon}
                    className="w-10 mx-auto md:w-auto md:h-11 lg:h-14 xl:h-20"
                    alt=""
                  />
                  <div>
                    <h6 className="text-fmd lg:text-fbase xl:text-flg font-bold mb-1">
                      {content.title}
                    </h6>
                    <p className="font-normal text-fsm lg:text-fmd xl:text-fbase text-gray-400">
                      {content.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="flex justify-center mt-6 md:mt-8 lg:mt-20 md:w-6/12 mx-auto">
            <div className="text-center md:text-left">
              <div className="flex flex-col md:flex-row gap-2 md:gap-3 lg:gap-5">
                <img
                  src="/src/img/icon11.png"
                  className="w-10 mx-auto md:w-auto md:h-11 lg:h-14 xl:h-20"
                  alt=""
                />
                <div>
                  <h6 className="text-fmd lg:text-fbase xl:text-flg font-bold mb-1">
                    Fitur Super Lengkap
                  </h6>
                  <p className="text-fsm lg:text-fmd xl:text-fbase text-gray-400">
                    Bekerja sama dengan ratusan biller terpercaya, kami
                    menyediakan pulsa, kuota data, token PLN, tiket kereta api
                    hingga tiket pesawat dengan cepat.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Join;
