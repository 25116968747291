import React, { useEffect, useState } from "react";
import icon1 from "../../../../images/icon1.png";
import icon2 from "../../../../images/icon2.png";
import icon3 from "../../../../images/icon3.png";
import icon4 from "../../../../images/icon4.png";
import icon5 from "../../../../images/icon5.png";
import icon6 from "../../../../images/icon6.png";

import axios from "axios";
import apiConfig from "../../../../api/apiConfig";

import Swal from "sweetalert2";

import PulsaTab from "./Partials/PulsaTab";
import PaketDataTab from "./Partials/PaketDataTab";
import TokenListrikTab from "./Partials/TokenListrikTab";
import VoucherDigitalTab from "./Partials/VoucherDigitalTab";
import EmoneyTab from "./Partials/Emoney";
import EwalletTab from "./Partials/Ewallet";

const PembelianTab = () => {
  const [currentLayananTab, setCurrentLayananTab] = useState(1);

  const getProvider = async (id, datas, setDatas) => {
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl + `bisabiller/product/v1/${id}`}`
      );
      const res = response.data.data;
      setDatas({
        ...datas,
        items: res.map((item, itemIdx) => {
          return {
            value: item.product_name,
            id: item.product_id,
            label: (
              <div key={itemIdx} className="flex">
                <div className="icon w-12 lg:w-10 mr-4">
                  <img src={item.img_url} alt="" />
                </div>
                <div className="text-fmd my-auto">
                  <h5>{item.product_name}</h5>
                </div>
              </div>
            ),
          };
        }),
      });

      // Success 🎉
    } catch (error) {
      Swal.fire({
        title: "Failed",
        text: "Terjadi error",
        icon: "error",
        confirmButtonText: "OK",
      });
      // Error 😨

      console.log(datas);
      // console.log(error);
    }
  };

  const tabs = [
    {
      id: 1,
      tabTitle: "Pulsa",
      icon: icon1,
      content: <PulsaTab getProvider={getProvider} />,
    },
    {
      id: 2,
      tabTitle: "Paket Data",
      icon: icon2,
      content: <PaketDataTab getProvider={getProvider} />,
    },
    {
      id: 3,
      tabTitle: "Token Listrik",
      icon: icon3,
      content: <TokenListrikTab getProvider={getProvider} />,
    },
    {
      id: 4,
      tabTitle: "Voucher Digital",
      icon: icon4,
      content: <VoucherDigitalTab getProvider={getProvider} />,
    },
    {
      id: 5,
      tabTitle: "E-Money",
      icon: icon5,
      content: <EmoneyTab getProvider={getProvider} />,
    },
    {
      id: 6,
      tabTitle: "E-Wallet",
      icon: icon6,
      content: <EwalletTab getProvider={getProvider} />,
    },
  ];
  const handleTabClick = (id) => {
    setCurrentLayananTab(id);
  };

  return (
    <>
      <div className="grid grid-cols-6 px-2 y-4 gap-2 md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
        {tabs.map((tab, tabIdx) => {
          return (
            <button
              disabled={currentLayananTab === tab.id}
              key={tabIdx}
              id={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`${
                currentLayananTab === tab.id ? "border-b-4" : ""
              }  text-center mx-auto hover:border-b-4 border-orange-400 py-3 xl:py-4`}
            >
              <img
                src={tab.icon}
                className="h-5 md:h-6 lg:h-7 mb-2 md:mb-3 lg:mb-4 mx-auto"
                alt=""
              />
              <p className="text-fxs md:text-fsm lg:text-fmd font-bold text-primary">
                {tab.tabTitle}
              </p>
            </button>
          );
        })}
      </div>
      {tabs.map((tab, tabIdx) => {
        return (
          <>
            <div key={tabIdx} id={tab.tabTitle}>
              {currentLayananTab === tab.id ? tab.content : ""}
            </div>
          </>
        );
      })}
    </>
  );
};

export default PembelianTab;
