import React, { useEffect } from "react";
import apiConfig from "../../api/apiConfig";
import axios from "axios";

import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Daftar from "./Daftar";
import Download from "./Download";
import Features from "./Features";
import Feedback from "./Feedback";
import InfoPromo from "./InfoPromo";
import Jariyah from "./Jariyah";
import Join from "./Join";
import Jumbotron from "./Jumbotron";
import Keunggulan from "./Keunggulan";
import Layanan from "./Layanan/Layanan";
import Manfaaps from "./Manfaaps";
import MetodePembayaran from "./MetodePembayaran";
import User from "./User";
import Tonton from "./Tonton";

const LandingPage = () => {
  const getParent = async () => {
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl + `bisabiller/product/parent`}`
      );
      const res = response.data.data;
      console.log(res);
      // Success 🎉
    } catch (error) {
      // Error 😨
      console.log(error);
    }
  };
  useEffect(() => {
    getParent();
  }, []);

  return (
    <>
      <Navbar active="home" />
      {/* Jumbotron */}
      <Jumbotron />
      {/* layanan */}
      <Layanan />
      {/* Join BTU */}
      <Join />
      {/* Manfaaps */}
      <Manfaaps />
      {/* Metode Pembayaran */}
      <MetodePembayaran />
      {/* Total Pengguna */}
      <User />
      {/* Jariyah */}
      <Jariyah />
      {/* Features */}
      <Features />
      {/* Keunggulan */}
      <Keunggulan />
      {/* Daftar */}
      <Daftar />
      {/* Youtube */}
      <Tonton />
      {/* 
      Feedback
      {/* <Feedback/> */}
      {/* Blog  */}
      <InfoPromo />

      {/* Download */}
      <Download />
      {/* Footer */}
      <Footer />
    </>
  );
};

export default LandingPage;
