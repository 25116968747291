import React from "react";
import bglanding1 from "../../images/bglanding1.png";

const Jariyah = () => {
  return (
    <>
      <section
        className="rounded-lg h-24 md:h-48 lg:h-64 w-11/12 mx-auto my-8 lg:my-14 xl:my-16"
        style={{
          backgroundImage: `url(${bglanding1})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1 className="text-fmd md:text-2xl lg:text-f2xl xl:text-f3xl font-bold py-5 md:py-10 lg:py-16 xl:py-24 w-11/12 mx-auto leading-tight lg:leading-snug">
          Hanya di Bisatopup, <br />
          <span className="text-primary">Setiap transaksi </span> sudah <br />
          termasuk <span className="text-primary">Sedekah Jariyah</span>
        </h1>
      </section>
    </>
  );
};

export default Jariyah;
