import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

import icon12 from "../../images/icon12.png";
import icon13 from "../../images/icon13.png";
import icon14 from "../../images/icon14.png";
import icon15 from "../../images/icon15.png";
import content9 from "../../images/content9.png";
import eta from "../../images/eta.png";
import bsi from "../../images/bsi.png";
import kominfo from "../../images/kominfo.png";

import jumbotronImg from "../../images/content9.png";
import bgtentang1 from "../../images/bgtentang1.png";

const Privacy = () => {
  return (
    <>
      <Navbar active="kebijakan-privasi" />

      <section className="header">
        <div className="py-4 md:py-0">
          <section className="w-11/12 lg:w-10/12 mx-auto">
            <div className="md:grid grid-cols-12">
              <div className="md:hidden">
                <img src={jumbotronImg} alt="" />
              </div>
            </div>
          </section>

          <div className="hidden md:grid items-center grid-cols-12 w-11/12  mx-auto  gap-4">
            <div className="col-span-1"></div>
            <div className="col-span-5 flex flex-col gap-3 lg:gap-4">
              <h1 className="text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Kebijakan Privasi
              </h1>

              <p className="text-fsm lg:text-fmd xl:text-fbase font-light text-gray-400">
                Produk platform Payment Point Online Bank <br /> (PPOB) berbasis
                aplikasi android buatan{" "}
                <span className="font-bold text-gray-600">
                  PT. <br /> Bisatopup Teknologi Indonesia
                </span>{" "}
                yang terbentuk <br /> dari tahun 2014 hingga sekarang.
              </p>
            </div>

            <div className="col-span-6 p-0">
              <img src={content9} className="w-full p-0" alt="" />
            </div>
          </div>
        </div>

        <div className="md:hidden w-10/12 mx-auto">
          <div className="w-11/12 flex flex-col gap-2 py-4">
            <h1 className="text-flg font-bold leading-tight">
              {" "}
              Kami adalah <br />
              <span className="text-primary">Bisatopup.</span>{" "}
            </h1>

            <p className="text-fsm text-gray-400">
              Produk platform Payment Point Online Bank <br /> (PPOB) berbasis
              aplikasi android buatan PT. <br /> Bisatopup Teknologi Indonesia
              yang terbentuk <br /> dari tahun 2014 hingga sekarang.
            </p>
          </div>
        </div>
      </section>

      <section className="content my-10">
        <div className="w-11/12 lg:w-10/12 mx-auto px-12 py-8 lg:px-24 lg:py-16 border rounded overflow-y-auto max-h-[600px]">
          <div className="syarat-ketentuan">
            <div className="judul text-fbase lg:text-fxl font-bold  text-center my-4">
              Kebijakan Privasi
            </div>
            <div className="isi text-fsm lg:text-fmd text-justify">
              <div>
                Bisatopup menghormati privasi pengguna dan berkomitmen untuk
                melindunginya dalam segala hal. Bisatopup tidak menjual,
                menukar, atau memperlihatkan segala informasi yang berkaitan
                dengan pengguna situs atau pengguna aplikasi Bisatopup. Selama
                Anda login ke Bisatopup, Bisatopup menggunakan cookie yang akan
                berakhir pada saat Anda logout. Semua transaksi Anda dan
                informasi lainnya disimpan secara rahasia sesuai dengan
                ketentuan hukum yang berlaku di Indonesia dan kebijakan internal
                Bisatopup.
                <br />
                <br />
                Kebijakan privasi ini memberitahukan Anda bagaimana kami
                menggunakan informasi pribadi yang dikumpulkan di situs atau
                aplikasi ini. Silakan baca kebijakan privasi ini sebelum
                menggunakan layanan atau mengirimkan informasi pribadi apapun.
                Mengakses dan menggunakan situs atau aplikasi ini menandakan
                Anda menerima semua kegiatan yang dijelaskan dalam kebijakan
                privasi.
                <br />
                <br />
                Kebijakan ini dapat berubah, dan perubahan akan diumumkan serta
                perubahan hanya akan berlaku untuk kegiatan dan informasi yang
                akan datang, bukan yang sudah terjadi. Anda dianjurkan untuk
                membaca kebijakan privasi setiap kali Anda mengunjungi dan
                menggunakan layanan untuk memastikan bahwa Anda memahami
                bagaimana informasi pribadi yang Anda berikan akan digunakan.
              </div>
              <div className="koleksi my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Koleksi Informasi Pengguna
                </div>
                <div className="isi">
                  Untuk memanfaatkan aplikasi jasa Bisatopup, pengguna
                  diharuskan untuk memberikan informasi tertentu untuk proses
                  pendaftaran yang mungkin termasuk namun tidak terbatas pada :
                  <ol className="list-none list-outside space-y-2 mt-4">
                    <li>a) nama Anda, </li>
                    <li>b) nomor telepon,</li>
                    <li>c) password.</li>
                  </ol>
                </div>
              </div>
              <div className="pengumpulan-data my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Data yang dikumpulkan
                </div>
                <div className="isi">
                  <ol className="list-disc list-outside space-y-2 mt-4">
                    <li>
                      Profil Pengguna : Kami dapat memastikan bahwa data yang
                      kami simpan sesuai dengan data yang Anda telah berikan
                      saat melakukan pendaftaran di Bisatopup ataupun saat
                      melakukan Transaksi dan Pembayaran di layanan kami.
                    </li>
                    <li>
                      Nomor Pelanggan : Saat melakukan transaksi di Bisatopup,
                      beberapa diantaranya mewajibkan di cantumkannya informasi
                      terkait nomor tujuan atau nomor pelanggan yang akan di
                      gunakan untuk melakukan Transaksi.
                    </li>
                    <li>
                      Kontak : Kontak dapat digunakan sebagai Nomor Pelanggan
                      untuk melakukan transaksi. Kontak hanya akan dapat di
                      akses jika pengguna berkenan untuk memberikan izin
                      mengakses kontak di perangkatnya.
                    </li>
                    <li>
                      {" "}
                      Lokasi : Lokasi akan kami gunakan sebagai pencatat lokasi
                      pengguna aplikasi kami.{" "}
                    </li>
                    <li>
                      Sosial Login : Anda dapat menggunakan akun sosial media
                      Anda (Seperti Facebook & Google) untuk mendaftarkan dan
                      masuk ke situs atau aplikasi Bisatopup. Informasi ini akan
                      digunakan untuk melengkapi Profil Pengguna.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="data-pengguna my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Data yang kami peroleh dari penggunaan layanan
                </div>
                <div className="isi">
                  Kami mengumpulkan beberapa informasi terkait layanan Kami
                  untuk memudahkan kami melacak tren dan permasalahan yang
                  terkair dengan layanan di Bisatopup. Berikut ini beberapa
                  informasi yang kami kumpulkan:
                  <ol className="list-disc list-outside space-y-2 mt-4">
                    <li>
                      Informasi Perangkat Kami mengumpulkan informasi spesifik
                      terkait perangkat yang sedang Anda gunakan untuk mengakses
                      Bisatopup (seperti Model Perangkat, Sistem Operasi, Unique
                      Device Identifiers, dan jaringan).
                    </li>
                    <li>
                      Informasi Log Saat Anda menggunakan layanan Mobilepulsa,
                      kami secara otomatis akan mengumpulkan Log yang akan di
                      simpan di Server, diantaranya: - Crash Log, Aktifitas
                      Sistem, Pengaturan Perangkat, Browser, Bahasa, Tanggal dan
                      Jam serta URL - IP Address - Cookie
                    </li>
                  </ol>
                </div>
              </div>
              <div className="Cookies-akun my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Pengumpulan Informasi Elektronik (Cookies)
                </div>
                <div className="isi">
                  Untuk meningkatkan respon dari situs bisatopup.co.id untuk
                  pengguna kami, kami mungkin menggunakan "cookies", atau alat
                  elektronik serupa untuk mengumpulkan informasi untuk
                  menetapkan setiap pengunjung unik, nomor acak sebagai
                  identifikasi pengguna (User ID) untuk memahami kepentingan
                  individu pengguna menggunakan diidentifikasi komputer. Kecuali
                  anda secara sukarela mengidentifikasi diri (melalui
                  pendaftaran, misalnya), Bisatopup tidak akan memiliki cara
                  untuk mengetahui siapa anda, bahkan jika kita menetapkan
                  cookie untuk komputer anda. Satu-satunya informasi pribadi
                  cookie dapat berisi informasi yang anda berikan. Cookie tidak
                  dapat membaca data dari hard drive anda. pengiklan kami juga
                  dapat menetapkan cookie mereka sendiri ke browser anda ( jika
                  anda mengklik iklan mereka), sebuah proses yang kami tidak
                  mengontrol.
                </div>
              </div>
              <div className="berbagi-informasi my-8">
                <div className="judul font-bold text-center md:text-left mb-2 text-fmd md:text-fbase">
                  Berbagi Informasi
                </div>
                <div className="isi">
                  Bisatopup berbagi informasi pribadi yang sensitif kepada pihak
                  ketiga tanpa mendapatkan persetujuan terlebih dahulu dari
                  Pengguna dalam keadaan terbatas berikut:
                  <ol className="list-disc list-outside space-y-2 mt-4">
                    <li>
                      Ketika diminta atau diharuskan oleh hukum atau oleh
                      pengadilan atau badan pemerintah atau otoritas untuk
                      mengungkapkan, untuk tujuan verifikasi identitas, atau
                      untuk pencegahan, deteksi, investigasi termasuk insiden
                      cyber, atau untuk penuntutan dan hukuman dari pelanggaran.
                      Pengungkapan ini dibuat dengan itikad baik dan keyakinan
                      bahwa pengungkapan tersebut secara wajar diperlukan untuk
                      menegakkan ketentuan ini; untuk mematuhi undang-undang dan
                      peraturan yang berlaku.
                    </li>
                    <li>
                      Bisatopup mengusulkan untuk berbagi informasi tersebut
                      kepada partner atau perusahaan yang berkaitan dengan
                      promosi untuk keuntungan anda. Promosi ini berupa dalam
                      bentuk voucher, hadiah, atau cash-back. Informasi Keamanan
                    </li>
                  </ol>
                </div>
              </div>
              Kami mengambil langkah-langkah keamanan yang sesuai untuk
              melindungi terhadap akses tidak sah atau perubahan yang tidak sah,
              pengungkapan atau kerusakan data. Ini termasuk tinjauan internal
              pada pengumpulan data, penyimpanan dan pengolahan kami dan
              langkah-langkah keamanan, termasuk enkripsi yang sesuai dan
              langkah-langkah keamanan fisik untuk menjaga dari akses tidak sah
              ke sistem tempat kami menyimpan data pribadi. Semua informasi yang
              dikumpulkan dari Bisatopup disimpan dengan aman di dalam database
              Bisatopup dikendalikan. Database disimpan di server aman di
              belakang firewall; akses ke server adalah sandi - dilindungi dan
              sangat terbatas.
              <br />
              <br />
              Anda berhak untuk menyalin setiap informasi Anda yang tercantum
              dan tersimpan dalam sistem kami. Anda juga berhak untuk
              memperbaiki setiap kesalahan dalam informasi tersebut. Sebagaimana
              telah disebutkan di atas, Anda berhak untuk meminta kami berhenti
              menggunakan informasi Anda untuk tujuan pemasaran. Isi dari situs
              ini merupakan hak cipta dan/atau merek dagang dari pengarang
              aslinya kecuali dicatat dan dijelaskan lain pada halaman terkait.
            </div>
          </div>
          <div className="kontak">
            <div className="judul text-fbase lg:text-fxl font-bold  text-center my-4">
              Kontak Kami
            </div>
            <div className="isi text-fsm lg:text-fmd text-justify">
              Jika Anda memiliki pertanyaan atau keluhan, jangan ragu untuk
              menghubungi kami di{" "}
              <span className="underline font-medium">
                {" "}
                support@bisatopup.co.id.{" "}
              </span>
              Kami selalu siap untuk membantu mengatasi masalah atau keluhan
              Anda.
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Privacy;
