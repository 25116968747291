import React from "react";

import berbagilink from "../../images/berbagi.png";
import jualanku from "../../images/jualanku.png";
import mutasiBank from "../../images/mutasiBank.png";

const InfoPromo = () => {
  const contents = [
    {
      title: "Berbagi.link",
      desc: "Platform paling Mudah untuk Kumpulkan banyak informasi hanya dalam satu tautan.",
      image: berbagilink,
      link: "https://berbagi.link/",
    },
    {
      title: "Jualanku.link",
      desc: "Platform Termudah untuk Jualan Online Anda! 100% GRATIS",
      image: jualanku,
      link: "https://jualanku.link/",
    },
    {
      title: "MutasiBank",
      desc: "Kelola berbagai macam Mutasi Akun Bank Anda dengan Mudah dalam satu tempat",
      image: mutasiBank,
      link: "https://mutasibank.co.id/",
    },
  ];

  return (
    <>
      <section className="my-8 lg:my-14 xl:my-16">
        <div className="text-center">
          <h1 className="text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl text-center font-bold leading-tight">
            Info dan Promo
          </h1>
          <p className="border-b-4 lg:border-b-8 border-orange-400 inline text-white">
            ----------
          </p>
        </div>

        <div className="grid grid-cols-4 lg:grid-cols-12 my-6 md:my-14 lg:my-16 xl:my-20 gap-3 md:gap-5 lg:gap-7 xl:gap-9 w-11/12 md:w-10/12 mx-auto">
          {contents.map((content, contentIdx) => {
            return (
              <a
                href={content.link}
                target="_blank"
                key={contentIdx}
                className="col-span-4"
              >
                <div className="border hover:shadow-md rounded-lg lg:min-h-[340px]">
                  <img
                    className="md:py-12 md:px-24 px-6 py-8"
                    src={content.image}
                    alt=""
                  />

                  <div className="p-1 md:p-3 lg:p-4 xl:p-5">
                    <h5 className="text-fmd md:text-fbase xl:text-flg leading-tight font-bold">
                      {content.title}
                    </h5>

                    <p className="pt-3 pb-2 text-fsm md:text-fmd xl:text-fbase text-gray-500">
                      {content.desc}
                    </p>
                  </div>
                </div>
              </a>
            );
          })}
        </div>

        {/* <div className="flex justify-center">
          <button className="bg-primary shadow-none hover:shadow-md hover:shadow-teal-300 shadow-teal-400 w-5/12 md:w-3/12 mx-auto rounded-xl xl:rounded-2xl py-3 md:py-4 my-1">
            <div className="flex items-center justify-center gap-2 md:gap-3 xl:gap-4 text-white text-fsm lg:text-fmd xl:text-fbase font-bold">
              <h5>Baca lebih lanjut</h5>
              <i className="fas fa-arrow-right"></i>
            </div>
          </button>
        </div> */}
      </section>
    </>
  );
};

export default InfoPromo;
