import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

import icon12 from "../../images/icon12.png";
import icon13 from "../../images/icon13.png";
import icon14 from "../../images/icon14.png";
import icon15 from "../../images/icon15.png";
import content9 from "../../images/content9.png";
import eta from "../../images/eta.png";
import bsi from "../../images/bsi.png";
import kominfo from "../../images/kominfo.png";

import jumbotronImg from "../../images/content9.png";
import bgtentang1 from "../../images/bgtentang1.png";

const About = () => {
  return (
    <>
      <Navbar active="about" />

      <section className="header">
        <div className="py-4 md:py-0">
          <section className="w-11/12 lg:w-10/12 mx-auto">
            <div className="md:grid grid-cols-12">
              <div className="md:hidden">
                <img src={jumbotronImg} alt="" />
              </div>
            </div>
          </section>

          <div className="hidden md:grid items-center grid-cols-12 w-11/12  mx-auto  gap-4">
            <div className="col-span-1"></div>
            <div className="col-span-5 flex flex-col gap-3 lg:gap-4">
              <h1 className="text-fxl lg:text-f2xl xl:text-f3xl font-bold leading-tight">
                Kami adalah <br />
                <span className="text-primary">Bisatopup</span>
              </h1>

              <p className="text-fsm lg:text-fmd xl:text-fbase font-light text-gray-400">
                Produk platform Payment Point Online Bank <br /> (PPOB) berbasis
                aplikasi android buatan{" "}
                <span className="font-bold text-gray-600">
                  PT. <br /> Bisatopup Teknologi Indonesia
                </span>{" "}
                yang terbentuk <br /> dari tahun 2014 hingga sekarang.
              </p>
            </div>

            <div className="col-span-6 p-0">
              <img src={content9} className="w-full p-0" alt="" />
            </div>
          </div>
        </div>

        <div className="md:hidden w-10/12 mx-auto">
          <div className="w-11/12 flex flex-col gap-2 py-4">
            <h1 className="text-flg font-bold leading-tight">
              {" "}
              Kami adalah <br />
              <span className="text-primary">Bisatopup.</span>{" "}
            </h1>

            <p className="text-fsm text-gray-400">
              Produk platform Payment Point Online Bank <br /> (PPOB) berbasis
              aplikasi android buatan PT. <br /> Bisatopup Teknologi Indonesia
              yang terbentuk <br /> dari tahun 2014 hingga sekarang.
            </p>
          </div>
        </div>
      </section>

      <section className="w-10/12 mx-auto my-10 md:my-14 lg:my-16">
        <div className="text-center md:text-left mb-8 md:mb-14">
          <h4 className="text-fxs md:text-fsm lg:text-fmd   font-bold text-primary">
            VISI & MISI
          </h4>
          <h1 className="font-bold pb-0 text-fbase md:text-flg lg:text-fxl xl:text-f2xl">
            Visi kami
          </h1>
          <p className="border-b-4 border-orange-400 inline text-white">
            ---------
          </p>
        </div>

        <div className="w-11/12 md:w-full mx-auto">
          <h1
            className="px-4 py-4 rounded-lg text-flg bg-white font-bold text-left md:hidden text-gray-800 mb-10 md:mb-8"
            style={{ boxShadow: "1px 1px 4px rgb(230, 230, 230)" }}
          >
            <span className="text-yellow-500">“</span>
            Menjadi perusahaan <br />
            <span className="text-primary">
              {" "}
              Fintech Syariah No.1 <br />{" "}
            </span>
            di Indonesia.
            <span className="text-yellow-500">”</span>
          </h1>

          <div className="hidden md:grid grid-cols-12 gap-7  w-full">
            <div className="col-span-5">
              <h1 className="font-bold text-fmd md:text-fbase  lg:text-fxl xl:text-f2xl  my-2">
                Misi <span className="hidden md:inline">Kami</span>
              </h1>

              <div>
                <div>
                  <ul className="list-disc text-fsm lg:text-fmd  xl:text-fbase flex flex-col gap-4 lg:gap-6 xl:gap-7 mb-4 lg:mb-6 xl:mb-7">
                    <li>
                      Menjadikan aplikasi pilihan utama bagi customer dalam
                      berbagai topup digital
                    </li>
                    <li>Mengembangkan bisnis atas dasar nilai-nilai Syariah</li>
                  </ul>
                  <ul className="list-disc text-fsm lg:text-fmd  xl:text-fbase flex flex-col gap-4 lg:gap-6 xl:gap-7">
                    <li>Update teknologi yang melampaui harapan customer</li>
                    <li>Meningkatkan kualitas fitur dan layanan customer</li>
                  </ul>
                </div>
              </div>
            </div>

            <h1
              className="col-span-7 rounded-lg pt-6 md:pt-8 lg:pt-6 xl:pt-10 pl-4 lg:pl-14 xl:pl-16 text-fbase md:text-fxl lg:text-f2xl xl:text-f3xl xl:leading-tight font-bold text-left hidden md:block text-gray-800 mb-10 bg-white"
              style={{ boxShadow: "1px 1px 5px rgb(230, 230, 230)" }}
            >
              <span className="text-yellow-500">“</span>
              Menjadi perusahaan <br />
              <span className="text-primary">
                {" "}
                Fintech Syariah No.1 <br />
              </span>
              di Indonesia.
              <span className="text-yellow-500">”</span>
            </h1>
          </div>
        </div>
      </section>

      <section>
        <div className="text-center">
          <h1 className="font-bold pb-0 text-fbase md:text-flg lg:text-fxl xl:text-f2xl">
            Pengguna
          </h1>
          <p className="border-b-4 border-orange-400 inline text-white">
            ---------
          </p>
        </div>
        <div
          style={{
            minHeight: "10vh",
            width: "90%",
            backgroundImage: `url(${bgtentang1})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
            backgroundRepeat: "no-repeat",
            margin: "0 auto",
          }}
        >
          <div className="grid grid-cols-4 gap-2  md:w-11/12 mx-auto py-3 lg:py-8">
            <div className="col-span-1">
              <img
                src={icon12}
                className="h-7 md:h-9 lg:h-12 xl:h-14  mx-auto"
                alt="content"
              />
              <h4 className="text-gray-400 text-fxs md:text-fsm xl:text-fmd text-center my-4">
                TOTAL COSTUMER
              </h4>
              <p className="block font-bold text-fmd md:text-fbase lg:text-flg xl:text-fxl ext-gray-900 text-center ">
                220K++
              </p>
            </div>
            <div className="col-span-1">
              <img
                src={icon13}
                className="h-7 md:h-9 lg:h-12 xl:h-14  mx-auto"
                alt="content"
              />
              <h4 className="text-gray-400 text-fxs md:text-fsm xl:text-fmd text-center my-4">
                TOTAL TRANSAKSI
              </h4>
              <p className="block font-bold text-fmd md:text-fbase lg:text-flg xl:text-fxl ext-gray-900 text-center ">
                4.800K++
              </p>
            </div>
            <div className="col-span-1">
              <img
                src={icon14}
                className="h-7 md:h-9 lg:h-12 xl:h-14  mx-auto"
                alt="content"
              />
              <h4 className="text-gray-400 text-fxs md:text-fsm xl:text-fmd text-center my-4">
                TOTAL DOWNLOAD
              </h4>
              <p className="block font-bold text-fmd md:text-fbase lg:text-flg xl:text-fxl ext-gray-900 text-center ">
                210K++
              </p>
            </div>
            <div className="col-span-1">
              <img
                src={icon15}
                className="h-7 md:h-9 lg:h-12 xl:h-14  mx-auto"
                alt="content"
              />
              <h4 className="text-gray-400 text-fxs md:text-fsm xl:text-fmd text-center my-4">
                TOTAL AGEN
              </h4>
              <p className="block font-bold text-fmd md:text-fbase lg:text-flg xl:text-fxl ext-gray-900 text-center ">
                11K++
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="md:w-11/12 mx-auto lg:w-10/12 ">
        <div className="my-12 md:my-16 lg:my-20 xl:my-24">
          <div className="text-center md:text-left w-11/12 mx-auto my-8">
            <h6 className="text-fxs  md:text-fsm xl:text-base text-primary font-bold">
              CERTIFICATION
            </h6>
            <h2 className="text-fmd md:text-fbase xl:text-fxl font-bold mt-3">
              Sertifikasi & Lisensi Bisatopup.
            </h2>
            <p className="border-b-4 border-orange-400 inline text-white mb-6">
              ---------
            </p>
          </div>

          <div className="grid grid-cols-3">
            <div className="col-span-1 items-center w-10/12 mx-auto">
              <div className="md:mb-6 lg:mb-8  xl:mb-10">
                <img
                  src={bsi}
                  className="h-10 md:h-12 lg:h-14 xl:h-20 mx-auto"
                  alt=""
                />
              </div>
              <div className="hidden md:flex flex-col gap-5 md:gap-6 lg:gap-7 xl-gap-10 ">
                <div className="text-fxs md:text-fsm xl:text-fmd text-left md:text-center">
                  <h6>Certificate Number </h6>
                  <h6 className="font-bold">000659.01/DJAI.PSE/05/2021</h6>
                  <br />
                </div>
                <div className="text-fxs md:text-fsm xl:text-fmd text-left md:text-center">
                  <p>Info selengkapnya</p>
                  <h6 className="font-bold text-primary underline hover:text-cyan-500">
                    {" "}
                    <a
                      href="https://berbagi.link/Bisatopup/SertifikasiISO27001"
                      target={"_blank"}
                    >
                      bagi.to/ISOBTU
                    </a>{" "}
                  </h6>
                </div>
              </div>
              <div className="md:hidden flex flex-col gap-3 text-center">
                <div className="my-3">
                  <p className="text-fxs text-gray-600 mb-2">
                    Certificate Number
                  </p>
                  <h6 className="text-fxs font-bold text-gray-600">
                    IS 749143 (ISO/IEC27001:2013)
                  </h6>
                </div>
                <div>
                  <h5 className="text-fsm">Info selengkapnya:</h5>
                  <h6 className="text-primary hover:text-cyan-500 font-bold text-fsm">
                    <a
                      href="https://berbagi.link/Bisatopup/SertifikasiISO27001"
                      target={"_blank"}
                    >
                      {" "}
                      bagi.to/ISOBTU
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-span-1 items-center overflow-hidden w-10/12 mx-auto">
              <div className="md:mb-6 lg:mb-8  xl:mb-10">
                <img
                  src={kominfo}
                  className="h-10 md:h-12 lg:h-14 xl:h-20 mx-auto"
                  alt=""
                />
              </div>
              <div className="hidden md:flex flex-col gap-5 md:gap-6 lg:gap-7 xl-gap-10">
                <div className="text-fxs md:text-fsm xl:text-fmd text-left md:text-center">
                  <h6>Tanda Daftar KOMINFO </h6>
                  <h6>Penyelenggara Sistem Elektronik</h6>
                  <h6 className="font-bold">000659.01/DJAI.PSE/05/2021</h6>
                </div>
                <div className="text-fxs md:text-fsm xl:text-fmd text-left md:text-center">
                  <p>Info selengkapnya</p>
                  <h6 className="font-bold text-primary underline hover:text-cyan-500">
                    {" "}
                    <a
                      href="https://berbagi.link/Bisatopup/LisensiPSEKominfo"
                      target={"_blank"}
                    >
                      {" "}
                      berbagi.link/Bisatopup/LisensiPSE
                    </a>
                  </h6>
                </div>
              </div>

              <div className="md:hidden flex flex-col gap-3 text-center">
                <div className="my-3">
                  <p className="text-fxs text-gray-600">Tanda daftar KOMINFO</p>
                  <p className="text-fxs text-gray-600">
                    Penyelenggara Sistem Elektronik
                  </p>
                  <h6 className="text-fxs font-bold text-gray-600">
                    000659.01/DJAI.PSE/05/2021
                  </h6>
                </div>
                <div>
                  <h5 className="text-fsm">Info selengkapnya:</h5>
                  <h6 className="text-primary hover:text-cyan-500 font-bold text-fsm">
                    <a
                      href="https://berbagi.link/Bisatopup/LisensiPSEKominfo"
                      target={"_blank"}
                    >
                      berbagi.link/Bisatopup/LisensiPSE
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-span-1 overflow-hidden items-center w-10/12 mx-auto">
              <div className="md:mb-6 lg:mb-8  xl:mb-10">
                <img
                  src={eta}
                  className="h-10 md:h-12 lg:h-14 xl:h-20 mx-auto"
                  alt=""
                />
              </div>
              <div className="hidden md:flex flex-col gap-5 md:gap-6 lg:gap-7 xl-gap-10">
                <div className="text-fxs md:text-fsm xl:text-fmd text-left md:text-center">
                  <h6>Tervalidasi untuk Akad &</h6>
                  <h6>SOP Sesuai Syariah</h6>
                  <h6 className="font-bold"> AKI-2001 </h6>
                </div>
                <div className="text-fxs md:text-fsm xl:text-fmd text-left md:text-center">
                  <p>Info selengkapnya</p>
                  <h6 className="font-bold text-primary underline hover:text-cyan-500">
                    {" "}
                    <a
                      href="https://berbagi.link/Bisatopup/LisensiETA"
                      target={"_blank"}
                    >
                      {" "}
                      berbagi.link/Bisatopup/LisensiETA{" "}
                    </a>
                  </h6>
                </div>
              </div>
              <div className="md:hidden flex flex-col gap-3 text-center">
                <div className="my-3">
                  <p className="text-fxs text-gray-600 mb-2">
                    Tervalidasi untuk akad & <br /> SOP sesuai syariah
                  </p>

                  <h6 className="text-fxs font-bold text-gray-600">AKI-2001</h6>
                </div>
                <div>
                  <h5 className="text-fsm">Info selengkapnya:</h5>
                  <h6 className="text-primary hover:text-cyan-500 font-bold text-fsm">
                    <a
                      href="https://berbagi.link/Bisatopup/LisensiETA"
                      target={"_blank"}
                    >
                      berbagi.link/Bisatopup/LisensiETA
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-8 md:my-10 lg:my-12 xl:my-16">
        <h2 className="text-fmd md:text-fbase xl:text-fxl font-bold mt-3 mb-6 text-center">
          Lokasi Kami
        </h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.2221684565543!2d106.84072141527666!3d-6.365287364040807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3d5ece55e35%3A0x705e0344eb936962!2sBISATOPUP!5e0!3m2!1sid!2sid!4v1670320808243!5m2!1sid!2sid"
          width="100%"
          height="400"
          className="border-0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <Footer />
    </>
  );
};

export default About;
